import React, { useEffect, useState, useMemo } from 'react';
import FormComponent from './FormComponent';
import { useAppSelector } from '../../../app/hooks';
import { useGetCustomersQuery, useGetAllJobTypesQuery, useGetSupervisorsQuery, useGetForemenQuery, useGetEstimatorsQuery, useGetJCAccountantQuery } from '../../dropdowns/dropdownApi';
import { AutocompleteOptions } from '../../../utils/interfaces';


const ProjectForm = (props: any) => {
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);

    const params = useMemo(() => ({ company_id: company?._id }), [company]);
    const { data: customers } = useGetCustomersQuery(params);

    const jobTypeParams = useMemo(() => ({ division_id: division?._id }), [division]);
    const { data: all_job_types } = useGetAllJobTypesQuery(jobTypeParams);

    const {data: supervisors} = useGetSupervisorsQuery(jobTypeParams);
    const {data: foremen} = useGetForemenQuery(jobTypeParams);
    const {data: estimators} = useGetEstimatorsQuery(jobTypeParams);
    const {data: jcAccountants} = useGetJCAccountantQuery(jobTypeParams);

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        setAutocompleteOptions({
            customer_id: {
                data: customers?.data || [],
                options: customers?.data?.map((customer: any) => customer.customer_description) || [],
            },
            job_types: {
                data: all_job_types?.data || [],
                options: all_job_types?.data?.map((job_type: any) => job_type.job_type_description) || [],
            },
            supervisor: {
                data: supervisors?.data || [],
                options: supervisors?.data?.map((supervisor: any) => supervisor.employee_name) || [],
            },
            foreman: {
                data: foremen?.data || [],
                options: foremen?.data?.map((foreman: any) => foreman.employee_name) || [],
            },
            jc_accountant: {
                data: jcAccountants?.data || [],
                options: jcAccountants?.data?.map((accountant: any) => accountant.employee_name) || [],
            },
            estimator: {
                data: estimators?.data || [],
                options: estimators?.data?.map((estimator: any) => estimator.employee_name) || [],
            },
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [customers, all_job_types, statuses, supervisors, foremen, jcAccountants, estimators]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} />;
};

export default ProjectForm;
