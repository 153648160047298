import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { dropdownApi } from '../features/dropdowns/dropdownApi';
import authReducer, { loadSuiteDetails } from '../features/auth/authSlice';
import apiReducer from '../features/api/apiSlice';
import layoutReducer from '../features/layout/layoutSlice';
import reportReducer from '../features/reports/reportSlice';
import dropdownReducer from '../features/dropdowns/dropdownSlice';
import StepperReducer from '../features/components/stepper/stepperSlice';
import SnackbarReducer from '../features/components/snackbar/snackbarSlice';
import { apiSliceV2 } from '../features/api/apiSliceV2';

// Combine all the reducers
const rootReducer = combineReducers({
  auth: authReducer,
  api: apiReducer,
  layout: layoutReducer,
  report: reportReducer,
  dropdown: dropdownReducer,
  stepper: StepperReducer,
  snack: SnackbarReducer,
  [dropdownApi.reducerPath]: dropdownApi.reducer, 
  [apiSliceV2.reducerPath]: apiSliceV2.reducer
});

// Create a function to configure the store
const initializeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(dropdownApi.middleware, apiSliceV2.middleware),
    devTools: process.env.NODE_ENV === 'development',
  });

  // Hydrate the state by dispatching loadSuiteDetails
  store.dispatch(loadSuiteDetails());

  return store;
};

// Export the store
export const store = initializeStore();

// Define the types for RootState, AppDispatch, and AppThunk
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
