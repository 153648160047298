import React, { Component } from 'react';
import {ReactComponent as SvgImage} from '../../../assets/SomethingWentWrong.svg';
import { Grid, Typography } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in error boundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid container justifyContent="center" >
          <Grid item xs={12} justifyContent="center">
            <Typography fontWeight={600} textAlign="center" variant="h4">Something went wrong, please reload the page.</Typography>
          </Grid>
          <Grid item xs sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <SvgImage 
              style={{ 
                width: '100%', 
                height: '100%', 
                minHeight: "440px",
                minWidth: "440px",
                maxHeight: "660px",
                maxWidth: "660px",
              }} 
              />
        </Grid>
        </Grid>
      
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;