import { Grid } from "@mui/material";
import DetailHeader from "../../components/header/DetailHeader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useState, useEffect, useMemo } from "react";
import { AutocompleteOptions } from "../../../utils/interfaces";
import { useGetSupervisorsQuery, useGetForemenQuery, useGetEstimatorsQuery, useGetJCAccountantQuery, useGetAllJobTypesQuery, useGetCustomersQuery } from "../../dropdowns/dropdownApi";
import { apiRouteMap } from "../../routes/urls";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { useUpdateDataMutation } from "../../api/apiSliceV2";
import { useLocation } from "react-router-dom";
import { setSelectedItem } from "../../components/stepper/stepperSlice";

export const translationRequired = [
    "proposal_reference",
    "budget_reference",
    "company_id",
    "division_id",
    "customer_id",
    "builders",
    "customers",
    "job_types",
    "job_type",
    "supervisor",
    "estimator",
    "foreman",
    "jc_accountant"
];

const ProjectHeader = (props:any) => {
    const {isHeaderVisible} = props;
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const dispatch = useAppDispatch();
    const params = useMemo(() => ({ company_id: company?._id }), [company]);
    const { data: customers } = useGetCustomersQuery(params);
    const { selectedItem, selectedProject } = useAppSelector((state: any) => state.api);
    const location = useLocation();
    const jobTypeParams = useMemo(() => ({ division_id: division?._id }), [division]);
    const { data: all_job_types } = useGetAllJobTypesQuery(jobTypeParams);
    const {data: supervisors} = useGetSupervisorsQuery(jobTypeParams);
    const {data: foremen} = useGetForemenQuery(jobTypeParams);
    const {data: estimators} = useGetEstimatorsQuery(jobTypeParams);
    const {data: jcAccountants} = useGetJCAccountantQuery(jobTypeParams);
    const path = (location.pathname.split("/").length > 3) ?
    `/${location.pathname.split("/")[3]}`
    :
    `/${location.pathname.split("/")[1]}`;
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});
    const [updateData] = useUpdateDataMutation();

    const translateIds = (jsonData:any) => {
        try {
            for (const [key, val] of Object.entries(jsonData) as [string, any]) {
                if (translationRequired.includes(key)) {
                    switch (key) {
                        case "customer_id":
                            const customer = autocompleteOptions?.[key]?.data?.find((c:any) => c?.customer_description === val?.customer_description);
                            jsonData[key] = customer?._id || val; 
                            break;
                        case "supervisor":
                            const supervisor = autocompleteOptions?.[key]?.data?.find((s:any) => {
                                return s?.employee_name === val;
                            });

                            jsonData[key] = supervisor?._id || ""; 
                            break;
                        case "foreman":
                            const foreman = autocompleteOptions?.[key]?.data?.find((f:any) => f?.employee_name === val);
                            jsonData[key] = foreman?._id || ""; 
                            break;
                        case "estimator":
                            const estimator = autocompleteOptions?.[key]?.data?.find((e:any) => e?.employee_name === val);
                            jsonData[key] = estimator?._id || ""; 
                            break;
                        case "jc_accountant":
                            const jc_accountant = autocompleteOptions?.[key]?.data?.find((j:any) => j?.employee_name === val);
                            jsonData[key] = jc_accountant?._id || ""; 
                            break;
                        case "job_types":
                            if (Array.isArray(val)) {
                                const translatedJobTypes = val?.map((job_type) => {
                                    const found = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === job_type);
                                    return found?._id || job_type;   
                                });
                                jsonData[key] = translatedJobTypes;   
                            }   
                            break;
                        default:
                            break;
                    }
                }
            }
            return jsonData;
        } catch (error) {
            console.log(error);
        }
        return jsonData;
    };

    const handleSave = async (formData:any) => {
        try {

            const body = translateIds(formData);
            const response = await updateData({
                tagName: `/projects/${selectedItem?._id}`,
                url: apiRouteMap.get(path).update(selectedItem?._id),
                body,
            }).unwrap();
            dispatch(setSelectedItem(response?.data));
            dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
        } catch (error: any) {
            const errorMessage = error?.data?.message || 'Failed to update data';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        }
    };

    useEffect(() => {
        setAutocompleteOptions({
            customer_id: {
                data: customers?.data || [],
                options: customers?.data?.map((customer: any) => customer.customer_description) || [],
            },
            job_types: {
                data: all_job_types?.data || [],
                options: all_job_types?.data?.map((job_type: any) => job_type.job_type_description) || [],
            },
            supervisor: {
                data: supervisors?.data || [],
                options: supervisors?.data?.map((supervisor: any) => supervisor.employee_name) || [],
            },
            foreman: {
                data: foremen?.data || [],
                options: foremen?.data?.map((foreman: any) => foreman.employee_name) || [],
            },
            jc_accountant: {
                data: jcAccountants?.data || [],
                options: jcAccountants?.data?.map((accountant: any) => accountant.employee_name) || [],
            },
            estimator: {
                data: estimators?.data || [],
                options: estimators?.data?.map((estimator: any) => estimator.employee_name) || [],
            },
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [customers, all_job_types, statuses, supervisors, foremen, jcAccountants, estimators]);

    return(
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <DetailHeader
            isHeaderVisible={isHeaderVisible}
            autocompleteOptions={autocompleteOptions}
            editable={true}
            handleSave={handleSave}
            path={path}
          />
        </Grid>
    );
}

export default ProjectHeader;