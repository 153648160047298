import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks'; // Custom hooks for Redux
import { currentAuthenticatedUser, logout, reset } from '../auth/authSlice'; // Import auth actions
import Spinner from '../spinner/Spinner';
import InactiveSessionModal from './InactiveSessionModal'; // Your InactiveSessionModal component
import useInactivityTimer from './useInactivityTimer'; // Your inactivity timer hook
import CustomSnack from '../components/snackbar/CustomSnack';
import BasicSpeedDial from '../components/speed-dial/BasicSpeedDial';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isAuthenticated, isLoading, user, suiteDetailsConfirmed } = useAppSelector((state: any) => state.auth);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const snack = useAppSelector((state:any) => state.snack);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // Check if the current session is authenticated
                await dispatch(currentAuthenticatedUser()).unwrap();
            } catch (error) {
                // If authentication fails, log out and navigate to login
                console.log(error);
                await dispatch(logout());
                dispatch(reset());
                navigate('/login'); // Redirect to login if the session is invalid
            }
        };
        checkAuth(); 
    }, [dispatch, navigate, isAuthenticated]);

    useEffect(() => {
        if (!suiteDetailsConfirmed) navigate("/"); // Redirect to root if suiteDetailsConfirmed is false
    }, [suiteDetailsConfirmed, navigate]);

    // const handleRefreshSession = async () => {
    //     try {
    //         // Try refreshing the session
    //         await dispatch(refreshToken());
    //         setModalOpen(false); // Close the modal after session refresh
    //     } catch (error) {
    //         console.log("Failed to refresh session:", error);
    //         dispatch(logout());
    //         dispatch(reset());
    //         navigate('/login'); // Redirect to login if session refresh fails
    //     }
    // };

    // Hook to track inactivity, and trigger modal when timeout occurs
    useInactivityTimer(() => {
        setModalOpen(true); // Open modal after inactivity
    });

    // Show a spinner while checking the authentication state
    if (isLoading || (!user && !isAuthenticated)) {
        return <Spinner />;
    }

    // If authenticated, render the children
    return (
        <>
            {children}
            <InactiveSessionModal
                open={isModalOpen}
                onClose={() => {
                    setModalOpen(false);
                   // handleRefreshSession(); // Refresh session if the user chooses to stay logged in
                }}
            />
            <BasicSpeedDial />
        </>
    );
};

export default PrivateRoute;
