import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, MenuItem, Select, useTheme } from '@mui/material';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import styles from './NavigationHeader.module.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomButton from '../button/CustomButton';
import { setSelectedItem } from '../stepper/stepperSlice';
import { useGetProjectsQuery, useGetStartsQuery } from '../../dropdowns/dropdownApi';

const navContext = [ 
  {
    route: "starts", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'
  },
  {
    route: "reports", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'

  },
  {
    route: "warehouse-orders", 
    getUrl: (project_id:string) => `starts?project_id=${project_id}`,
    id: 'start_id',
    value: 'subjob_number'

  },
]

const startMenuItems:any = {
  "starts": [
    "starts",
    'reports',
    'warehouse-orders'
  ],
  'warehouse-orders': [,
    'warehouse-orders',
    'starts',
    'reports',
  ],
  'reports': [
    "reports",
    'starts',
    'warehouse-orders'
  ]
}
//console.log
const NavigationHeader = (props:any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { project_id, start_id } = useParams();
  const {company, division} = useAppSelector((state:any) => state.auth);
  const theme = useTheme();
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [selectedStart, setSelectedStart] = useState<any>(null);
  const [locationName, setLocationName] = useState<string>("");
  const context:any = navContext?.find((c:any) => c?.route === locationName);
  const { data: starts } = useGetStartsQuery({project_id: project_id || ""});
  const params = useMemo(() => ({
    company_id: company?._id, 
    division_id: division?._id
  }),[company, division])
  const {data: projects} = useGetProjectsQuery(params);

  useEffect(() => {
    if(projects){
      const project = projects?.data?.find((p:any) => {
        return p?._id === project_id
      });
      setSelectedProject(project);

    }
  },[project_id, projects]);

  useEffect(() => {
    const name = (location.pathname.split("/").length > 3) ? 
      location.pathname.split("/")[3] 
    : 
      location.pathname.split("/")[1]; //?.slice(0, -1);
    setLocationName(name);

  },[location, context]);

  useEffect(() => {
    if(starts && start_id){
      const start = starts?.data?.find((start:any) => start?._id === start_id);
      setSelectedStart(start);
      dispatch(setSelectedItem(start));
    }
  }, [starts, start_id]);

  const handleProjectChange = (event:any) => {

    const project = projects?.data && projects?.data?.find((p:any) => p.job_number === event.target.value);
    setSelectedProject(project);
    navigate(`/projects/${project._id}`);
  };

  const handleRouteChange = (event:any) => {
    const contextPath = event?.target?.value;
    setLocationName(contextPath)
    navigate(`/projects/${selectedProject._id}/${contextPath}/${selectedStart._id}`);
  }

  const handleItemIdChange = (event:any) => {
    const item:any = starts?.data?.find((s:any) => s?.[context?.value] === event.target.value);
    console.log(item)
    setSelectedStart(item);
    dispatch(setSelectedItem(item));
    const contextPath = location.pathname.split("/")[3];
    navigate(`/projects/${selectedProject?._id}/${contextPath}/${item?._id}`);
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{height: "auto"}}>
      <Grid item>
        <CustomButton color="primary" variant="outlined" onClick={handleBackNavigation} startIcon={<ArrowBackIosNewSharpIcon />} size="small" className={styles.button}>
          Back
        </CustomButton>
      </Grid>
      <Grid item xs sx={{fontWeight: 800}}>
        <Box sx={{width: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1}}>
          <Box>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "end", gap: 1, width: "auto"}}>
              <Select
                value={selectedProject?.job_number || ''}
                defaultValue={selectedProject?.job_number}
                onDoubleClick={() => navigate(`/projects/${selectedProject?._id}`)} 
                onChange={handleProjectChange}
                displayEmpty
                className={styles.title}
                variant="standard"
                margin='dense'
                size='small'
                sx={{ ml: 1, width: "100%", border: "none", fontSize: 32, fontWeight: 500, color: theme.palette.primary.main}}
              >
                {projects?.data && projects?.data?.map((project:any) => (
                  <MenuItem key={project?._id} value={project?.job_number}>
                    {project?.job_number}
                  </MenuItem>
                ))}
              </Select>
          
              {starts?.data?.length > 0 && start_id && (
                <Select
                  value={selectedStart?.[context?.value] || ''}
                  defaultValue={selectedStart?.[context?.value]}
                  onChange={handleItemIdChange}
                  displayEmpty
                  className={styles.title}
                  variant="standard"
                  margin='dense'
                  size='small'
                  color='primary'
                  sx={{ ml: 1, width: "100%", border: "none", fontSize: 32, fontWeight: 500,}}
                >
                  {starts?.data?.map((item:any) => (
                    <MenuItem key={item?.[context?.value]} value={item?.[context?.value]}>
                      {item?.[context?.value]}
                    </MenuItem>
                  ))}
                </Select>
              
            )}
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1, width: "auto"}}>
            {startMenuItems?.[`${locationName}`]?.includes(locationName)&& (
            <Box sx={{width: "auto"}}>
              <Select
              value={locationName}
              defaultValue={locationName}
              onChange={handleRouteChange}
              displayEmpty
              className={styles.title}
              variant="standard"
              margin='dense'
              size='small'
              sx={{ ml: 1, width: "auto", border: "none", fontSize: 22, fontWeight: 500}}
              >
                {startMenuItems?.[`${locationName}`]?.map((route:any) => (
                  <MenuItem defaultValue={locationName} key={route} value={route}>
                    {route}
                  </MenuItem>
                ))}
              </Select>
            </Box>)}
          </Box>
          
        </Box>
          </Box>
          

        
        
      </Grid>
      <Grid item className={styles.spacer}></Grid>
    </Grid>
  );
};

export default NavigationHeader;



