import { useAppDispatch } from "../../../../app/hooks";
import CustomButton from "../../../components/button/CustomButton";
import { updateDoc } from "../../../api/apiSlice";
import { useEffect } from "react";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";

const FulFillOrder = (props:any) => {
    const {order, orderType, handleRefresh} = props;
    const dispatch = useAppDispatch();
    const [updateData] = useUpdateDataMutation();

    const handleStatusUpdate = async () => {
      try {
        const requestData = {
          ...order,
          order_status: "FulFilled"
        };
        const response = await updateData({
            tagName: "/warehouse-orders",
            url: `/warehouse/orders?id=${order?._id}`,
            body: requestData,
        }).unwrap();
        dispatch(showSnackbar({ message: response?.message || 'Data update is successful', type: 'success' }));
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));

      }
    }
    return (
        <CustomButton size="small" color="success" variant="contained" sx={{color: "white"}} onClick={handleStatusUpdate}>FulFill Order</CustomButton>
    );
}

export default FulFillOrder;