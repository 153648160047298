import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { useTheme } from '@mui/material/styles';
import styles from './CustomDataGrid.module.css';  

function CustomDataGrid({ rows, columns, isLoading, detailPanelExpandedRowIds, handleDetailPanelExpandedRowIdsChange, getDetailPanelContent, getDetailPanelHeight, deltaRows, type }:any) {
    const theme = useTheme();

    const getRowClassName = (params:any) => {
        let style = {};
        if(type === "delta"){
             const row = deltaRows?.find((delta:any) => delta?._id === params?.id);
             style = (row?.delta >= 0) ? styles.positiveDelta : styles.negativeDelta;
             style = detailPanelExpandedRowIds.includes(params.id) ? style : '';
        }else{
            style = detailPanelExpandedRowIds.includes(params.id) ? `${styles.highlighted}` : '';
        }  
        return `${style}`;
    }
    return (
        <DataGridPremium
            sx={{
                fontSize: 14,
                fontWeight: 500,
                border: "1px solid rgba(255,255,255,1)",
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: theme.palette.primary.main,
                    opacity: "0.8"
                },
            }}
            editMode="row"
            getRowId={(row:any) => row._id}
            density="compact"
            rows={rows}
            columns={columns || []}
            loading={isLoading}
            rowHeight={60}
            rowBuffer={10}
            slots={{
                detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
                detailPanelCollapseIcon: KeyboardArrowUpSharpIcon,
            }}
            slotProps={{ toolbar: { type: "proposals" } }}
           //hideFooter
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            getRowClassName={(params) => getRowClassName(params)}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        />
    );
}

export default CustomDataGrid;
