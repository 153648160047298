import { Box, Grid } from '@mui/material';
import { GridToolbarContainer} from '@mui/x-data-grid';
import React from 'react';
import DropdownMenuButton from '../../button/DropdownButton';


const ExportToolbar = (props: any) => {
    const {exportItems} = props;

    return(
        <Box>
            <GridToolbarContainer>
                {exportItems && 
                <Grid item xs={12}>
                    <DropdownMenuButton {...props}/>
                </Grid>}
            </GridToolbarContainer>
        </Box>
        
    )
}

export default ExportToolbar;
