import WarehouseOrderCols from "../components/columns/WarehouseOrderCols";
import CustomGridToolbar from "../components/data-grid/toolbars/CustomGridToolbar";

import WarehouseSearchToolbar from "./warehouse-toolbar/WarehouseSearchToolbar";

const useWarehouseConfig = () => {

    return {
        tabs: [
            {
                route: "/warehouse-orders", 
                index: 0,
                label: 'Orders Requested',
                expandable: true,
                deletable: true,
                header: true,
                steps: [],
                columns: WarehouseOrderCols?.WarehouseOrderDetailColumns,
                panelColumns: WarehouseOrderCols?.WarehouseOrderFulfillItemColumns,
                toolbar: CustomGridToolbar,
                panelToolbar: WarehouseSearchToolbar,
                panelToolbarProps: {
                    action: "FulFill"
                },
                tabWithFS: false,
                getUrl: '/warehouse/orders/starts', 
            },
        ]
    };
};

export default useWarehouseConfig;