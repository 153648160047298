import { Switch, Stack, Typography, FormGroup, Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ImportComponent from './Import';
import PastableGridForm, { jobTypeRequired } from './PastableGridForm';
import RevisionGrid from "./RevisionGrid";
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import CreatePreBuiltOrders from '../../projects/pre-built-orders/CreateOrder';
import { useGetAllJobTypesQuery, useGetJobTypesQuery } from '../../dropdowns/dropdownApi';

const FormSelect = React.memo((props: any) => {
    const { step } = props;
    const { project_id } = useParams();
    const { division } = useAppSelector((state: any) => state.auth);
    const context = step?.route?.split("/")[1];
    const [selectedJobType, setSelectedJobType] = useState<string>("");
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>({});
    const [checked, setChecked] = useState<boolean>(false);

    const params = useMemo(() => ({
        project_id: project_id || "",
        division_id: division?._id || ""
    }), [division, project_id]);

    const { data: response } = useGetAllJobTypesQuery(params);

    const handleSelectionChange = useCallback(() => {
        setChecked(prevChecked => !prevChecked);
    }, []);

    useEffect(() => {
        if (response?.data) {
            setAutocompleteOptions({
                job_type: response?.data?.map((j:any) => j?.job_type_description)
            });
        }
    }, [response]);

    return (
        <>
            {step?.importUrl && (
                <FormGroup row>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ padding: "1em 0em 1em 0em" }}>
                    <Typography sx={{ fontWeight: 500 }}>Clipboard</Typography>
                    <Switch checked={checked} onChange={handleSelectionChange} color="primary" />
                    <Typography sx={{ fontWeight: 500 }}>File Import</Typography>
                </Stack>
                </FormGroup>
            )}

            {jobTypeRequired?.[context] && (
                <Grid item xs={12}>
                <Autocomplete
                    freeSolo
                    sx={{ width: "256px", pb: 1 }}
                    options={autocompleteOptions?.job_type || []}
                    getOptionLabel={(option: any) => option}
                    renderInput={(params: any) => (
                    <TextField
                        {...params}
                        label="Job Type"
                        variant="outlined"
                        size="small"
                    />
                    )}
                    value={selectedJobType}
                    onChange={(event, newValue) => {
                    setSelectedJobType(newValue || "");
                    }}
                />
                </Grid>
            )}

            {checked ? (
                <ImportComponent {...props} job_type={response?.data?.find((job_type:any) => job_type?.job_type_description === selectedJobType)} />
            ) : (
                <>
                {step?.route === "/proposals" && (
                    <RevisionGrid {...props} job_type={response?.data?.find((job_type:any) => job_type?.job_type_description === selectedJobType)} />
                )}
                {step?.route === "/prebuilt-orders" && (
                    <CreatePreBuiltOrders {...props} job_type={response?.data?.find((job_type:any) => job_type?.job_type_description === selectedJobType)} job_types />
                )}
                {!["/proposals", "/prebuilt-orders"].includes(step?.route) && (
                    <PastableGridForm {...props} job_type={response?.data?.find((job_type:any) => job_type?.job_type_description === selectedJobType)} />
                )}
                </>
            )}
</>

    );
});

export default FormSelect;
