import React from 'react';
import { routeList } from '../routes/routes';
import { Routes, Route, useNavigate } from 'react-router-dom';
import NotFound from '../components/NotFound';
import PrivateRoute from '../routes/PrivateRoute';
import { Box } from '@mui/system';
import Login from '../auth/Login';
import PasswordReset from '../auth/PasswordReset';
import { setNavigator } from '../../utils/navigator';

const MainContent = () => {
    const navigate = useNavigate();
    setNavigator(navigate);
    return(
        <Box sx={{height: "100%", width: "100%"}}>
            <Routes>
                {routeList.map(({ component: Component, ...route }) => (
                    <Route
                        key={route.id}  
                        path={route.path}
                        element={
                            <PrivateRoute>
                                <Component />
                            </PrivateRoute> 
                        }
                    />
                ))}
                <Route path="/login" element={<Login />}/>
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="*" element={< NotFound />}/>
            </Routes>
        </Box>            
    );
}

export default MainContent;