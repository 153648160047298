
const concretePricingColumns = [
  { field: 'status', headerName: 'Status', type: 'string', sortable: true, width: 120, editable: true },
  { field: 'job_code', headerName: 'Job Code', type: 'string', sortable: true, width: 120, editable: true },
  { field: 'builder', headerName: 'Builder', type: 'string', sortable: true, width: 150, editable: true },
  { field: 'project', headerName: 'Project', type: 'string', sortable: true, width: 150, editable: true },
  { field: 'job_city', headerName: 'Job City', type: 'string', sortable: true, width: 150, editable: true },
  { field: 'vendor', headerName: 'Vendor', type: 'string', sortable: true, width: 150, editable: true },
  { 
    field: 'date_quote_received', 
    headerName: 'Date Quote Received', 
    type: 'date', 
    sortable: true, 
    width: 180, 
    editable: true,
    valueGetter: (params:any) => params.value ? new Date(params.value) : '',
  },
  { field: 'concrete_type', headerName: 'Concrete Type', type: 'string', sortable: true, width: 150, editable: true },
  { field: 'concrete_use', headerName: 'Concrete Use', type: 'string', sortable: true, width: 150, editable: true },
  { field: 'mix_number', headerName: 'Mix Number', type: 'string', sortable: true, width: 120, editable: true },
  { field: 'fiber_mix_ok', headerName: 'Fiber Mix Okay', type: 'boolean', sortable: true, width: 120, editable: true },
  { field: 'psi', headerName: 'PSI', type: 'number', sortable: true, width: 100, editable: true },
  { field: 'w_c', headerName: 'W/C Ratio', type: 'number', sortable: true, width: 100, editable: true },
  { field: 'load_size_cy', headerName: 'Load Size (CY)', type: 'number', sortable: true, width: 120, editable: true },
  { field: 'base_price', headerName: 'Base Price', type: 'string', sortable: true, width: 120, editable: true },
  { field: 'enviro_surcharge_per_load', headerName: 'Enviro Surcharge Per Load', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'fuel_surcharge_per_load', headerName: 'Fuel Surcharge Per Load', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'energy_surcharge_per_load', headerName: 'Energy Surcharge Per Load', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'freight_surcharge_per_load', headerName: 'Freight Surcharge Per Load', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'freight_surcharge_per_cy', headerName: 'Freight Surcharge Per CY', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'fiber_micro_per_cy', headerName: 'Fiber & Micro Per CY', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'grand_total', headerName: 'Grand Total', type: 'string', sortable: true, width: 150, editable: true },
  { 
    field: 'price_increase_date', 
    headerName: 'Price Increase Date', 
    type: 'date', 
    sortable: true, 
    width: 180, 
    editable: true,
    valueGetter: (params:any) => params.value ? new Date(params.value) : '',
  },
  { field: 'special_notes', headerName: 'Special Notes', type: 'string', sortable: true, width: 200, editable: true },
  { field: 'mix_submitted', headerName: 'Mix Submitted', type: 'boolean', sortable: true, width: 150, editable: true }
];


export default concretePricingColumns;
