import React from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import styles from "./detail.module.css";
import LotCols from "../../components/columns/lotColumns";

function LotDetailItems(props:any) {
    const {items} = props;
    const cols = LotCols?.useLotItemColumns() || [];
  return (
    <Grid container justifyContent="center" alignItems="center" className={styles.detailPanel} spacing={2}>
      <Grid item sm={12} xs={12}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)"
          }}
          hideFooter
          columns={cols}
          rows={items || []}
          editMode="row"
          getRowId={row => row?._id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
        /> 
      </Grid>
    </Grid>
  );
}

export default LotDetailItems;


  