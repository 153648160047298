import { GridColDef } from "@mui/x-data-grid-pro";

export const ProjectPlanDesignColumns: GridColDef[] = [
    {
      field: 'design_type',
      headerName: 'Design Type',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'designer',
      headerName: 'Designer',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'plan_set_date',
      headerName: 'Plan Set Date',
      type: 'date',
      width: 180,
      editable: true,
      valueGetter: (params:any) => params.value ? new Date(params.value) : '',
    },
    {
      field: 'plan_set_delta',
      headerName: 'Plan Set Delta',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'plan_set_name',
      headerName: 'Plan Set Name',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      type: 'string',
      width: 250,
      editable: true,
    },
    {
      field: 'pdf_link',
      headerName: 'PDF Link',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      type: 'date',
      width: 180,
      editable: false,
      valueGetter: (params:any) => params.value ? new Date(params.value) : null,
      hideable: true

    },
    {
      field: 'created_by',
      headerName: 'Created By',
      type: 'string',
      width: 150,
      editable: false,
      hideable: true
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      type: 'date',
      width: 180,
      editable: false,
      valueGetter: (params:any) => params.value ? new Date(params.value) : null,
      hideable: true
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      type: 'string',
      width: 150,
      editable: false,
      hideable: true
    },
  ];
  