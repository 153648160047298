import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';

const ConfirmationDialog = (props:any) => {
    const { row, id, open, handleDelete, setOpen } = props;
    const theme = useTheme();
    const [info, setInfo] = useState<any>({});
    const {isMobile} = useAppSelector((state:any) => state.layout);

    const excluded = [
        '_id',
        '_v',
        '__v',
        'project_id',
        'budget_id',
        'proposal_id',
        'contract_id',
        'items',
        'budget_items',
        'proposal_items',
        'contract_items',
        'order_items'
    ];

    useEffect(() => {
        setInfo(row);
    },[row, open]);
        
    return (
        <Dialog
            fullWidth
            open={open}
        >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent dividers>
                <Box sx={{display: "flex", flexDirection: (isMobile) ? "column" : "row "}}>
                    <Typography gutterBottom>
                        Pressing 'Yes' will permanently
                    </Typography>
                    <Typography sx={{marginLeft: 0.5, fontSize: 18, fontWeight: 600}} gutterBottom color="error">
                        DELETE:
                    </Typography>
                </Box>
                
                {info && Object.entries(info)?.filter(([key]) => !excluded.includes(key))?.map(([key, value]) => (
                    <Box sx={{display: "flex"}} key={key}>
                        <Typography>
                            {`${key}: `}
                        </Typography>
                        <Typography sx={{marginLeft: 0.5}} color={theme.palette.primary.main}>
                            {` ${value}`}
                        </Typography>
                    </Box>
                    
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>
                    No
                </Button>
                <Button variant="contained" onClick={() => handleDelete(id)}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
