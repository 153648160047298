import { GridColDef } from '@mui/x-data-grid-premium';

const checklistColumns: GridColDef[] = [
  { 
    field: 'checklist_item', 
    headerName: 'Checklist Item', 
    width: 150, 
    type: 'string',
    editable: true,
  },
  { 
    field: 'segment_one', 
    headerName: 'Segment One', 
    width: 120, 
    type: 'string',
    editable: true, 
    },
  { 
    field: 'segment_one_description', 
    headerName: 'Segment Description', 
    width: 200, type: 'string',
    editable: true, 
  },
  { 
    field: 'required', 
    headerName: 'Required', 
    width: 100, 
    type: 'boolean',
    editable: true,  
  },
  { 
    field: 'created_at', 
    headerName: 'Created At', 
    width: 180, 
    type: 'dateTime',
    valueFormatter: (params:any) => {
      return params.value ? new Date(params.value).toLocaleDateString() : '';
    },
    editable: false,
  },
  { field: 'created_by', headerName: 'Created By', width: 150, type: 'string' },
  { 
    field: 'updated_at', 
    headerName: 'Updated At', 
    width: 180, 
    type: 'dateTime',
    valueFormatter: (params:any) => {
      return params.value ? new Date(params.value).toLocaleDateString() : '';
    },
    editable: false,
  },
  { field: 'updated_by', headerName: 'Updated By', width: 150, type: 'string' }
];

export default checklistColumns;