import React, { useEffect, useState, useMemo } from 'react';
import FormComponent from './FormComponent';
import { useAppSelector } from '../../../app/hooks';
import { useGetCompanyNumbersQuery, useGetDivisionNumbersQuery } from '../../dropdowns/dropdownApi';
import { AutocompleteOptions } from '../../../utils/interfaces';


const EmployeeForm = (props: any) => {
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const { data: company_numbers } = useGetCompanyNumbersQuery();
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const params = useMemo(() => ({ 
        company_id: company_numbers?.data?.find((co:any) => co.company_number === selectedCompany)?._id 
    }), [selectedCompany, company_numbers]);
    const { data: division_numbers } = useGetDivisionNumbersQuery(params);

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        setAutocompleteOptions({
            company_id: {
                data: company_numbers?.data || [],
                options: company_numbers?.data?.map((co: any) => co.company_number) || [],
            },
            division_id: {
                data: division_numbers?.data || [],
                options: division_numbers?.data?.map((div: any) => div.division_number) || [],
            },
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [division_numbers, company_numbers, statuses]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} onCompanyChange={setSelectedCompany} />;
};

export default EmployeeForm;
