import { GridColDef } from "@mui/x-data-grid-premium";

const compnayColumns:GridColDef[] = [
    {
        field: 'company_number',
        headerName: 'Company Number',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        field: 'company_description',
        headerName: 'Company Description',
        type: 'string',
        width: 300,
        editable: true,
    },
    {
        field: 'company_principal',
        headerName: 'Company Principal',
        type: 'string',
        width: 200,
        editable: true,
    },
    {
        field: 'company_location',
        headerName: 'Company Location',
        type: 'string',
        width: 200,
        editable: true,
    },
    {
        field: 'company_revision_number',
        headerName: 'Revision Number',
        type: 'string',
        width: 150,
        editable: true,
    },
    {
        field: 'company_revision_description',
        headerName: 'Revision Description',
        type: 'string',
        width: 300,
        editable: true,
    },
    {
        field: 'company_revision_date',
        headerName: 'Revision Date',
        type: 'date',
        width: 180,
        editable: true,
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
    },
    {
        field: 'company_status',
        headerName: 'Status',
        type: 'string',
        width: 150,
        // Optional: Custom rendering or color based on status
        renderCell: (params:any) => (
            <span style={{ color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red' }}>
                {params.value}
            </span>
        ),
        editable: true,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: 'date',
        width: 180,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        type: 'string',
        width: 150,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        type: 'date',
        width: 180,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        type: 'string',
        width: 150,
        editable: false,
    }
];

export default compnayColumns;