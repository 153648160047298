import React, { useEffect, useState } from 'react';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import appAPI from '../../api/appAPI';
import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@mui/material';
import Spinner from '../../spinner/Spinner';

const DocumentViewer = ({ fileId, open, setOpen, contentType }:any) => {
    const [documentURL, setDocumentURL] = useState<string>("");

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await appAPI.get(`/documents/${fileId}`, {
                    responseType: 'blob'
                });
                const file = new Blob([response.data], { type: contentType });
                const fileURL = URL.createObjectURL(file);
                setDocumentURL(fileURL);
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        };

        if(open) fetchDocument();

        return () => {
            if(documentURL){
                URL.revokeObjectURL(documentURL);
                setDocumentURL("");
            }
        }
    }, [fileId, open]);

    const handleClose = () => {
        setOpen(false);
        if(documentURL){
            URL.revokeObjectURL(documentURL);
            setDocumentURL("");
        }
    }


    return (
        <Dialog open={open} onClose={() => console.log("closing")} fullScreen maxWidth="lg">
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <IconButton
                    aria-label="remove"
                    onClick={handleClose}
                    size="small"
                    sx={{opacity: 0.9, padding: 2, marginRight: 2}}
                >
                    <ClearSharpIcon fontSize="medium" />
                </IconButton>
            </Box>
            <DialogContent>
                {documentURL ? (
                    <object data={documentURL} type={contentType} width="100%" height="100%" />     
                ) : (
                    <Spinner />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DocumentViewer;
