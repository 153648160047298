
import { Grid, Typography, TextField, useTheme } from "@mui/material";
import { parseISO, isValid } from "date-fns";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { formatDateString, formatDateStringWithTime } from "../../../utils/formatDateString";
import CustomButton from "../../components/button/CustomButton";
import ConfirmationDialog from "../../components/data-grid/ConfirmationDialog";
import { apiRouteMap } from "../../routes/urls";
import { useDeleteDataMutation, useUpdateDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";

const dateWithTime:any = [
    'created_at',
    'updated_at'
];

const PanelHeader = (props:any) => {
    const { selectedItem, editable, expanded } = props;
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState(selectedItem);
    const [headerFields, setHeaderFields] = useState<any>({});
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const [deleteData] = useDeleteDataMutation();
    const [updateData] = useUpdateDataMutation();
    
    const excludedFields = [
        '_id',
        '_v',
        "__v",
        'job_id',
        'project_id',
        'budget_id',
        'proposal_id',
        'contract_id',
        "items",
        "budget_items",
        "order_items",
        "schedule_of_values_id",
        "schedule_of_values_ids"
    ];

    const nonEditableFields = [
        'created_by',
        'created_at',
        'updated_by',
        'updated_at',
        'order_number'
    ];

    useEffect(() => {
        setHeaderFields(selectedItem);
        setFormData(selectedItem);
    }, [selectedItem]);

    const isValidDate = (dateString:string, key:string) => {
        if(["job_number", "company_number", "division_number","subjob_number"].includes(key)) return false;
        const date = parseISO(dateString); 
        return isValid(date); 
    };

    const toggleEditMode = (event:React.MouseEvent) => {
        event.stopPropagation();
        setEditMode(!editMode);
    };

    const confirmDelete = () => {
        setOpen(!open);
      }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };
    
    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevents the accordion from collapsing
    };

    const handleSave = async (event:MouseEvent) => {
        try {
            event.stopPropagation();
            const response = await updateData({
                tagName: "/prebuilt-orders",
                body:  formData,
                url: apiRouteMap.get("/prebuilt-orders").update(selectedItem?._id)
            }).unwrap();
            dispatch(showSnackbar({message: response?.message || "Data updated", type: "success"}));

            const updatedItem = {
                ...selectedItem,
                ...formData,
            };
            setFormData(updatedItem);
            setHeaderFields({
                ...updatedItem,
            });
            setEditMode(false);
        } catch (error:any) {
            dispatch(showSnackbar({message: error?.message || "Data update failed", type: "error"}));

        }
    };

    const handleDelete = async () => {
        try{
            const response = await deleteData({
                tagName: "/prebuilt-orders",
                url: apiRouteMap?.get("/prebuilt-orders")?.delete(selectedItem?._id)
            }).unwrap();
            dispatch(showSnackbar({message: response?.message || "Data deleted", type: "success"}));
            setOpen(false);
        }catch(error:any){
            dispatch(showSnackbar({message: error?.message || "Data deletion failed", type: "error"}));
        }     
    }

    return (
        <Grid container sx={{width: "100%", height: "auto",   textTransform: "uppercase", opacity: 0.9, margin: "1rem"}}>
            <Grid container sx={{width: "100%", height: "auto" }}>
            {Object.entries(headerFields).map(([key, field]:any, index) => {
                if (excludedFields.includes(key)) return null;  
                return (
                    <Grid item key={index} lg={3} md={6} sm={8} xs={12}sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        {!editMode || nonEditableFields.includes(key) ? (
                            <>
                                <Typography variant="body2" sx={{fontWeight: 600}}>
                                    { `${key?.split("_")[0]} ${key?.split("_")[1] || ''}:`}
                                </Typography>
                                <Typography variant="body2" sx={{color: theme.palette.primary.main, marginLeft: 1, fontWeight: 600}}>
                                    {`${(isValidDate(field, key) ) ? (
                                        !dateWithTime.includes(key) ? formatDateString(field) :formatDateStringWithTime(field)
                                    ): field }`}
                                </Typography>
                            </>
                        ) : (
                            <>
                                {(key in selectedItem) &&
                                    <TextField
                                    label={key}
                                    name={key}
                                    value={formData?.[key]}
                                    onClick={handleClick}
                                    onChange={handleChange}
                                    variant="standard"
                                    fullWidth
                                />}
                            </>
                        )}
                    </Grid>
                );
            })}
            </Grid>
            {
                expanded &&
                <Grid container sx={{display: "flex", marginTop: "1em", width: "100%"}}>
                    <Grid item xs={6} sx={{display: "flex"}}>
                        {editable &&
                        <Grid item>
                            <CustomButton  color="primary" variant="outlined" size="small" onClick={toggleEditMode}>{editMode ? 'Cancel' : 'Edit'}</CustomButton>
                        </Grid>}
                        { editMode && 
                        <Grid item sx={{marginLeft: 1}}>
                            <CustomButton color="primary" variant="contained" size="small" onClick={(event:any) => handleSave(event)}>Save</CustomButton> 
                        </Grid>}
                    </Grid>
                    { editMode && 
                    <Grid item xs={6} sx={{display: "flex", justifyContent: "flex-end"}}> 
                        <Grid item>
                            <CustomButton color='error' variant="contained" size="small" onClick={() => confirmDelete()}>Delete</CustomButton>
                        </Grid>
                    </Grid>}
                </Grid>
            }
            
            <ConfirmationDialog
                row={headerFields} 
                open={open}
                setOpen={setOpen}
                handleDelete={handleDelete}
            />
        </Grid>
    );
};

export default PanelHeader;
