const aggregatePricingColumns = [
  { field: 'status', headerName: 'Status', type: 'string', width: 120, editable: true, sortable: true },
  { field: 'job_code', headerName: 'Job Code', type: 'string', width: 130, editable: true, sortable: true },
  { field: 'builder', headerName: 'Builder', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'project', headerName: 'Project', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'job_city', headerName: 'Job City', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'vendor', headerName: 'Vendor', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'vendor_address1', headerName: 'Vendor Address 1', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'vendor_address2', headerName: 'Vendor Address 2', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'vendor_phone_number', headerName: 'Vendor Phone Number', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'quote_no', headerName: 'Quote Number', type: 'string', width: 130, editable: true, sortable: true },
  { field: 'date_quote_received', headerName: 'Quote Received Date', type: 'date', width: 180, editable: true, sortable: true, valueGetter: (params:any) => params.value ? new Date(params.value) : null },
  { field: 'date_quote_expire', headerName: 'Quote Expiry Date', type: 'date', width: 180, editable: true, sortable: true, valueGetter: (params:any) => params.value ? new Date(params.value) : null },
  { field: 'sales_person', headerName: 'Sales Person', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'sales_tax', headerName: 'Sales Tax', type: 'number', width: 110, editable: true, sortable: true },
  { field: 'surcharge_percent', headerName: 'Surcharge Percent', type: 'number', width: 110, editable: true, sortable: true },
  { field: 'subcontractor_contact', headerName: 'Subcontractor Contact', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'subcontractor_email', headerName: 'Subcontractor Email', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'subcontractor_phone_no', headerName: 'Subcontractor Phone', type: 'string', width: 150, editable: true, sortable: true },
  { field: 'equip_type', headerName: 'Equipment Type', type: 'string', width: 160, editable: true, sortable: true },
  { field: 'aggregate_type', headerName: 'Aggregate Type', type: 'string', width: 160, editable: true, sortable: true },
  { field: 'aggregate_use', headerName: 'Aggregate Use', type: 'string', width: 160, editable: true, sortable: true },
  { field: 'quantity_per_load', headerName: 'Quantity Per Load', type: 'number', width: 170, editable: true, sortable: true },
  { field: 'quantity_uof_m', headerName: 'Quantity UofM', type: 'number', width: 170, editable: true, sortable: true },
  { field: 'quote_price', headerName: 'Quote Price', type: 'number', width: 140, editable: true, sortable: true },
  { field: 'sales_price', headerName: 'Sales Price', type: 'number', width: 140, editable: true, sortable: true },
  { field: 'surcharge_one_per_load', headerName: 'Surcharge 1/Load', type: 'number', width: 160, editable: true, sortable: true },
  { field: 'surcharge_two_per_load', headerName: 'Surcharge 2/Load', type: 'number', width: 160, editable: true, sortable: true },
  { field: 'surcharge_three_per_load', headerName: 'Surcharge 3/Load', type: 'number', width: 160, editable: true, sortable: true },
  { field: 'unloading_maxtime', headerName: 'Max Unloading Time', type: 'number', width: 180, editable: true, sortable: true },
  { field: 'unloading_maxtime_uof_m', headerName: 'Time Unit of Measure', type: 'string', width: 180, editable: true, sortable: true },
  { field: 'unloading_penalty_price', headerName: 'Unloading Penalty', type: 'number', width: 180, editable: true, sortable: true },
  { field: 'special_notes', headerName: 'Special Notes', type: 'string', width: 220, editable: true, sortable: true },
  { field: 'aggregate_submitted', headerName: 'Aggregate Submitted', type: 'boolean', width: 180, editable: true, sortable: true }
];

export default aggregatePricingColumns;
