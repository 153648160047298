import React, { useEffect, useState} from 'react';
import FormComponent from './FormComponent';
import { useAppSelector } from '../../../app/hooks';
import { AutocompleteOptions } from '../../../utils/interfaces';


const CompanyForm = (props: any) => {
    const { statuses } = useAppSelector((state: any) => state.dropdown);

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        setAutocompleteOptions({
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [statuses]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} />;
};

export default CompanyForm;
