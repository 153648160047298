import { GridColDef } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

// Define the columns
const documentColumns: GridColDef[] = [
  {
    field: 'filename',
    headerName: 'Filename',
    width: 300,
    renderCell: (params: GridRenderCellParams) => {
      const url = URL.createObjectURL(
        new Blob([params.row.content], { type: params.row.contentType })
      );

      if (
        params.row?.contentType?.includes('csv') ||
        params.row?.contentType?.includes('excel') ||
        params.row?.contentType?.includes('spreadsheet')
      ) {
        return (
          <a href={url} download={params.value}>
            {params.value}
          </a>
        );
      } else {
        return <span>{params.value}</span>;
      }
    },
  },
  {
    field: 'idType',
    headerName: 'ID Type',
    width: 120,
    valueGetter: (params:any) => params.row?.metadata?.idType || '',
  },
  {
    field: 'contentType',
    headerName: 'Content Type',
    width: 200,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    type: 'string',
    width: 200,
    valueGetter: (params:any) => new Date (params?.row?.metadata?.created_at) || new Date()
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    width: 200,
     valueGetter: (params:any) => params?.row?.metadata?.created_by || ''
  },
];

export default documentColumns;
