import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { AutocompleteOptions } from "../../../utils/interfaces";
import { useGetAllJobTypesQuery, useGetBudgetRevisionsQuery } from "../../dropdowns/dropdownApi";
import FormComponent from "./FormComponent";

const ProposalForm = (props:any) => {
    const { company, division } = useAppSelector((state:any) => state.auth);
    const { statuses} = useAppSelector((state:any) => state.dropdown);
    const {project_id, proposal_id} = useParams();
    const [selectedJobType, setSelectedJobType] = useState<string>("");
    const params = useMemo(() => ({ company_id: company?._id }), [company]);
    const jobTypeParams = useMemo(() => ({ division_id: division?._id}), [division]);
    const {data: job_types} = useGetAllJobTypesQuery(jobTypeParams);

    const jobTypeSelectionParams = useMemo(() => {
        const job_type = job_types?.data?.find((jt:any) => jt.job_type_description === selectedJobType );
        return {project_id: project_id || "", job_type_id: job_type?._id || ""}
    },[project_id, job_types, selectedJobType]);

    const {data: budget_revision_numbers} = useGetBudgetRevisionsQuery(jobTypeSelectionParams);
    
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        if(job_types && statuses){
            setAutocompleteOptions((prevOptions) => ({
                ...prevOptions,
                job_type: {
                    data: job_types?.data,
                    options: job_types?.data?.map((job_type:any) => job_type?.job_type_description)
                },
                status: {
                    data: statuses,
                    options: statuses
                },
            }));
        }
        
    }, [job_types, statuses]);
    
      useEffect(() => {
        if (selectedJobType && budget_revision_numbers) {
            const options = budget_revision_numbers?.data?.map((budget:any) => `${budget?.budget_name} - ${budget?.budget_rev_no}`);
            setAutocompleteOptions((prevOptions) => ({
                ...prevOptions,
                budget_reference: {
                    data: budget_revision_numbers?.data,
                    options
                },
            }));
        }
    }, [budget_revision_numbers, selectedJobType]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} onJobTypeChange={setSelectedJobType} />;
};

export default ProposalForm;
