import { GridColDef } from "@mui/x-data-grid-premium";

const projectColumns:GridColDef[] = [
  { field: 'job_number',
    headerName: 'Job Number',
    width: 150,
    type: 'string' 
  },
  {
    field: 'customer_id',
    headerName: 'Customer ID',
    width: 200,
    type: 'string',
    valueGetter: (params:any) => params.row.customer_id?.customer_legal_name || '',
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 200,
    type: 'string' 
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
    type: 'string' 
  },
  {
    field: 'tax_rate',
    headerName: 'Tax Rate',
    width: 120,
    type: 'number',
    editable: true,
    
    // Format the value for display
    valueFormatter: (params:any) => {
      if (params.value == null) {
        return '';
      }
      // Display as a whole number with a percent sign
      return `${(params.value * 100).toFixed(0)}%`;
    },
  
    // Parse the input value to a decimal format
    valueParser: (value:any) => {
      // Parse the whole number input to a decimal for server
      return Number(value) / 100;
    },
  },
  { 
    field: 'status',
    headerName: 'Status',
    width: 150,
    type: 'string',
    renderCell: (params:any) => (
        <span style={{ color: String(params?.value).toUpperCase() === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : '#0074D9' }}>
            {params.value}
        </span>
    ),
  },
  { field: 'job_types',
    headerName: 'Job Types',
    width: 160, 
    type: 'array',
    valueGetter: (params) => {
      const jobTypes = params.row.job_types;
      if (Array.isArray(jobTypes)) {
        return jobTypes.map((jobType: any) => jobType.job_type_description).join(', ');
      }
      return '';
    },
  },
  {
    field: 'supervisor',
    headerName: 'Supervisor',
    width: 160,
    type: 'string',
    filterable: false,
    valueGetter: (params:any) => params.row.supervisor?.employee_name || '',
  },
  { field: 'foreman',
    headerName: 'Foreman',
    width: 160,
    type: 'string',
    filterable: false,
    valueGetter: (params:any) => params.row.foreman?.employee_name || '', 
  },
  { 
    field: 'jc_accountant',
    headerName: 'JC Accountant',
    width: 160,
    type: 'string',
    filterable: false,
    valueGetter: (params:any) => params.row.jc_accountant?.employee_name || '', 
  },
  { field: 'estimator',
    headerName: 'Estimator',
    width: 160,
    type: 'string',
    filterable: false,
    valueGetter: (params:any) => params.row.estimator?.employee_name || '', 
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    type: 'date',
    valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    width: 150,
    type: 'string' 
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 200,
    type: 'date',
    valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    width: 150,
    type: 'string' 
  }
];


export default projectColumns;