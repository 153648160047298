
import { Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import React, {  } from 'react';
import { useLocation } from 'react-router-dom';
import { formatDateStringWithTime } from '../../../utils/formatDateString';

const LotColumns:GridColDef[] = [
  {
    field: 'lot_number',
    headerName: 'Lot #',
    width: 120,
    type: 'string',
    sortable: true,
    editable: true
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    type: 'string',
    width: 220,
    editable: true,
  },
  {
    field: 'garage_orientation',
    headerName: 'Orientation',
    type: 'string',
    sortable: true,
    width: 150,
    editable: true,
  },  
  {
    field: 'created_at',
    headerName: 'Created At',
    type: 'date',
    width: 180,
    filterable: true,
    editable: false,
    valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
    valueFormatter: (params: any) => {
        if (!params.value) {
        return '';
        }
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
      field: 'created_by',
      headerName: 'Created By',
      type: 'string',
      width: 150,
      filterable: true,
      editable: false,
  },
  {
      field: 'updated_at',
      headerName: 'Updated At',
      type: 'date',
      width: 180,
      filterable: true,
      editable: false,
      valueGetter: (params:any) => params.value ? new Date(params.value) : undefined,
      valueFormatter: (params: any) => {
          if (!params.value) {
          return '';
          }
          const date = new Date(params.value);
          return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
  },
  {
      field: 'updated_by',
      headerName: 'Updated By',
      type: 'string',
      width: 150,
      filterable: true,
      editable: false,
  }
];


const useLotItemColumns = ():GridColDef[] => {
  const location = useLocation();

  return [
    {
      field: 'job_type',
      headerName: 'Job Type',
      sortable: true,
      type: 'string',
      width: 160,
      editable: false,
    },
    {
      field: 'subjob_number',
      headerName: 'Subjob #',
      type: 'string',
      sortable: true,
      width: 80,
      editable: false,
      renderCell: (params:any) => {
        const startId = params.row.start_id; // Assuming start_id is a field in the row data
        const linkPath = `${location.pathname}/starts/${startId}`;
        return (
          <Link href={linkPath} style={{ textDecoration: 'none', color: 'blue' }}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'supervisor',
      headerName: 'Supervisor',
      type: 'string',
      sortable: true,
      width: 220,
      editable: true,
    },  
  ];
}

export default {LotColumns, useLotItemColumns};