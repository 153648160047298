import React, { useEffect, useState, useMemo } from 'react';
import FormComponent from './FormComponent';
import { useAppSelector } from '../../../app/hooks';
import { useGetAllJobTypesQuery } from '../../dropdowns/dropdownApi';
import { AutocompleteOptions } from '../../../utils/interfaces';

const BudgetForm = (props: any) => {
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);

    const jobTypeParams = useMemo(() => ({ division_id: division?._id }), [division]);
    const { data: job_types } = useGetAllJobTypesQuery(jobTypeParams);

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        setAutocompleteOptions({
            job_type: {
                data: job_types?.data || [],
                options: job_types?.data?.map((job_type: any) => job_type?.job_type_description) || []
            },
            status: {
                data: statuses || [],
                options: statuses || []
            }
        });
    }, [job_types, statuses]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} />;
};

export default BudgetForm;
