import React, { 
  useCallback, 
  useEffect, 
  useState } from 'react';
import { 
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clearFormErrors, createDoc, getListItems } from '../../api/apiSlice';
import { DataGridPremium,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModesModel,
  GridRowSelectionModel,
  useGridApiContext } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import ObjectID from 'bson-objectid';
import { evaluate } from 'mathjs';
import useSovOptions from '../hooks/useSovOptions';
import RevisionToolbar from '../data-grid/toolbars/RevisionToolbar';

export const requiredFields:any = {
  specifications: ["grouping_type", "specification", "specification_value", "job_type"],
  'fnd-concrete-pricings': ["jobNumber"],
  'flatwork-sfs': ["plan", "elevation", "option"],
  plps: ["plan", "elevation", "option"],
  'project-notes': ["description", "notes"],
  proposals: ["plan", "elevation", "option"],
  contracts: ["plan", "elevation", "option"],
}

const RevisionGrid = ({ step, activeStep, completed, handleClose, setCompleted, handleRefresh }:any) => {
  const {selectedItem, items, message, formErrors, isError, isLoading} = useAppSelector((state:any) => state.api);
  const {company_number, division_number} = useAppSelector((state:any) => state.auth);
  const {project_id, budget_id, proposal_id, contract_id} = useParams();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const [rows, setRows] = useState<any>([]);
  const context = step?.route?.split("/")[1];
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(0);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const {job_types, sovItems} = useAppSelector((state:any) => state.dropdown);
  const {isMobile} = useAppSelector((state:any) => state.layout);
  const [autocompleteOptions, setAutocompleteOptions] = useState<any>({});
  const [selectedValue, setSelectedValue] = useState<string>("");
  const[costTypes, setCostTypes] = useState<any>([]);
  const [increaseType, setIncreaseType] = useState<string>("");
  const [sovID, setSovID] = useState<string[]>([]);
  const [sovIdLabels, setSovIdLabels] = useState<string[]>([]);
  const [myMap, setMyMap] = useState<any>(new Map());
  const [radioValue, setRadioValue] = useState<string>("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [newValue, setNewValue] = useState("");
  const [price, setPrice] = useState<number>(1);
  const [costDistributionOptions, setCostDistributionOptions] = useState<any>([]);
  const [pinnedRows, setPinnedRows] = useState<any>([]);
  const {response} = useSovOptions(project_id || "", ""); 

  useEffect(() => {
    setAutocompleteOptions({
      sovOptions: response
    })
  }, [rows]);
 
  useEffect(() => {
    const getItems = async() => {
      try{
         if(selectedItem && sovItems && radioValue === "revision"){
          const response = await dispatch(getListItems({
            service: "app",
            url: `/proposals/increase/new?project_id=${project_id}&job_type=${selectedItem?.job_type}${selectedItem?.budget_reference ? `&budget_reference=${selectedItem?.budget_reference}` : ``}`
          }));

          if(response.meta.requestStatus === "fulfilled"){
            const responseItems = response?.payload?.data?.[project_id || ""]?.items;
            setRows(responseItems?.map((row:any) => {
              const sovId = sovItems?.find((item:any) => item?._id === row?.schedule_of_values_id);
              return {
                plan: sovId?.plan,
                elevation: sovId?.elevation,
                option: sovId?.option,
                total_price: sovId?.total_price,
                ...row
              }
            }));
            setNumRowsToAdd(rows?.length)
            return;
          } 
        }
        setRows([]);
      }catch(error:any){
        console.log(error) 
      }
    }
    getItems();

  }, [selectedItem, sovItems, radioValue]);

  useEffect(() => {
    setSelectedValue("")
  },[sovID, increaseType]);

  useEffect(() => {
    if (items && rows) {
      const uniqueCostDistributions = Array.from(new Set(items?.[project_id || ""]?.cost_distributions?.map((item:any) => item.cost_distribution)));
      setCostDistributionOptions(uniqueCostDistributions);
      const uniqueCostTypes = Array.from(new Set(items?.[project_id || ""]?.cost_types?.map((item:any) => item.cost_type)));
      setCostTypes(uniqueCostTypes);
    }
  }, [items]);

  const handleSubmit = async () => {
    try {
      const filtered = rows?.map((row:any) => {
        const {_id, ...remainingFields} = row;
        return {
          project_id, 
          schedule_of_values_id: myMap?.get(`${row.plan || ''}-${row.elevation || ''}-${row.option || ''}`),
          job_type: selectedItem?.job_type,
          ...remainingFields
      }})?.filter((row:any) => {
        const fields = requiredFields?.[context];
        if(!fields) return false;
        return fields.every((field: string) => {
          const value = row[field];
          return value !== undefined && value !== null && value !== '';
        });
      });

      console.log(rows)
      
      let id:string;
      switch(step?.idType){
        case 'project_id':
          id = project_id || "";
          break;
        case 'budget_id':
          id = budget_id || selectedItem?._id;
          break;
        case 'proposal_id':
          id = proposal_id || selectedItem?._id;
          break;
        case 'contract_id':
          console.log(selectedItem)
          id = contract_id || selectedItem?._id;
          break;
        default:
          id = project_id || "";
          break;
      }

      const submitBatch = async (batch:any) => {
        const response = await dispatch(createDoc({
          service: "app",
          data: JSON.stringify(batch),
          url: step?.saveUrl(id)
        }));
        return response;
      }

      const batchSize = 100;
      for(let i = 0; i < filtered?.length; i += batchSize){
        const batch = filtered?.slice(i, i + batchSize);
        const response = await submitBatch(batch);

        if(response.meta.requestStatus !== "fulfilled"){
          console.log("Batch submission failed.");
          break;
        } 
      }

      setCompleted({[activeStep]: true});
      dispatch(clearFormErrors());
     // await handleRefresh();
      
    } catch (error) {
      console.error(error);
    }
  };

  const clearGrid = () => {
    setRows([]);
    setSovID([]);
    setSovIdLabels([]);
    setCostDistributionOptions([]);
    setCostTypes([]);
    setIncreaseType("");
    setSelectedValue("");
    setPrice(0);
  }

  const clearRow = () => {
    setRows(rows?.filter((row:any) => row?._id !== rowSelectionModel[0]));
  }

  const addItem = () => {
    const newRow = {
      _id: new ObjectID(),
    };
    setRowSelectionModel((model:any) => [...model, newRow?._id])
    setRows((currentRows: any) => {
      // Find the index of the selected row
      const selectedIndex = currentRows.findIndex((row: any) => row?._id === rowSelectionModel[0]);
      // If no row is selected, add the new row at the end
      if (selectedIndex === -1) {
        return [...currentRows, newRow];
      }
      // Insert the new row after the selected row
      const updatedRows = [
        ...currentRows.slice(0, selectedIndex + 1),
        newRow,
        ...currentRows.slice(selectedIndex + 1)
      ];
      return updatedRows;
    });
  };

  const addMultipleItems = () => {
    setRowSelectionModel([]);
    for (let i = 0; i < numRowsToAdd; i++) {
      addItem();
    }
  };

  useEffect(() => {
    addMultipleItems();
  },[]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 
  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows:any) => rows.map((row:any) => (row._id === newRow._id) ? newRow : row));
    return newRow;
  }, []);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const updateSelectedRows = () => {
    let updatedRows = [...rows];
  
    rowSelectionModel.forEach((rowId:GridRowId) => {
      const rowToUpdate = updatedRows.find((row) => row._id === rowId);
      if (rowToUpdate) {
        const oldValue = rowToUpdate[selectedColumn];
        if(selectedColumn === "proposal_price"){
          console.log(selectedColumn);

        }
        const updatedValue =  (selectedColumn === "proposal_price") ?
          parseMathExpression(newValue, oldValue)
          :
          newValue;
        const updatedRow = { ...rowToUpdate, [selectedColumn]: updatedValue };
        const processedRow = processRowUpdate(updatedRow);
        updatedRows = updatedRows.map((row) => (row._id === rowId ? processedRow : row));
      }
    });
  
    setRows(updatedRows);
  };

  const parseMathExpression = (value: string, originalValue: number) => {
    try {
      // Replace 'x' with the original value
      const expression = value.replace(/x/g, originalValue.toString());
      // Evaluate the expression
      const result = evaluate(expression);
      if(isNaN(Number(result))) return value;

      return Number(result).toFixed(2);
    } catch (error) {
      console.error("Error parsing expression:", error);
      // If there is an error in parsing, return the original value
      return originalValue;
    }
  };

  const handleColumnChange = (event:any) => {
    setSelectedColumn(event.target.value);
  };
  
  const handleValueChange = (event:any) => {
    setNewValue(event.target.value);
  };

  const handleRadioChange = (e:any) => {
    const value = e?.target?.value;
    setRadioValue(value);
    if(value === "new"){
      clearGrid();
    }
  }

  const handleSovIdChange = (event:any) => {
    const value = event.target.value;
    setSovIdLabels(typeof value === 'string' ? value.split(',') : value,);
    let ids = [];
    for(const value of event.target.value){
      const [plan, elevation, option] = value?.split(" - ");
      // Find the row that matches the selected option
      const selectedRow = rows?.find((row:any) => 
        row.plan === plan.trim() && 
        row.elevation === elevation.trim() && 
        row.option === option.trim()
      );

      if (selectedRow) {
        ids.push(selectedRow?.schedule_of_values_id);
      }
    }
    setSovID(ids);


    console.log(sovID)
  };

  const getTakeoffQuantitySum = (id:string) => {
    const searchField = (increaseType === "Cost Distribution") ? "cost_distribution" : "cost_type";

    if (selectedValue) {
      console.log(items?.[project_id || ""]?.[`${searchField}s`])
      const sum = items?.[project_id || ""]?.[`${searchField}s`]
        ?.find((row:any) => {
          console.log(row?.schedule_of_values_id,id)
          return (row?.[searchField] === selectedValue && row?.schedule_of_values_id === id);
        })?.sum;
      console.log(sum)
      return sum; 
    } else {
      return 0;
    }
  };

  const handleCreateIncreaseItem = () => {
    // Iterate over each sovID in the array
    let sovIds:string[] = [];
    sovID.forEach((id:string) => {
      const row = rows?.find((row: any) => row?.schedule_of_values_id === id);
      let item = {
        _id: new ObjectID(),
        plan: row?.plan,
        elevation: row?.elevation,
        option: row?.option,
        option_code: row?.option_code,
        pricing_type: "Increase",
        schedule_of_values_id: id,
        quantity: 1,
      };
      sovIds.push(id);


      let m = new Map();
      rows?.forEach((row:any) => {
        let sum = m.get(row?.schedule_of_values_id) || 0;
        m.set(row?.schedule_of_values_id, sum + row?.proposal_price);
      });

      switch (increaseType) {
        
        case "Percent":
          const s = m?.get(id);
          setRows((rows: any) => [
            {
              ...item,
              proposal_price: Number(evaluate((s * (price / 100)).toFixed(2))),
            }, 
            ...rows
          ]);
          break;
        case "Lump Sum":
          setRows((prevRows: any) => [
            {
              ...item,
              proposal_price: Number(price).toFixed(2),
            },
            ...prevRows,
          ]);
          break;
        default:
          const sum = getTakeoffQuantitySum(id) || 0;
          console.log(sum)
          const result = evaluate(`${sum}*${price}`);
          setRows((currentRows: any) => [
            {
              ...item,
              proposal_price: Number(result).toFixed(2),
            },
            ...currentRows,
          ]);
          break;
      }
    });
    setRowSelectionModel(sovIds);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sx={{padding: "1rem 1rem 0 1rem"}}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select Type</FormLabel>
          <RadioGroup
            row
            name="createOption"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="new" control={<Radio />} label="New" />
            <FormControlLabel value="revision" control={<Radio />} label="Revision" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <>

        <Grid container sx={{justifyContent: "flex-start", alignItems: "center", padding: "1rem"}} gap={1}>
          {radioValue === "revision" &&
          <Grid container sx={{display: "flex", flexDirection:  isMobile? "column" : "row"}} gap={1}>
            <Grid item sx={{border: `1px solid ${theme.palette.primary.main} 0.9`, borderRadius: 1}}>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormControl sx={{ width: 220 }}>
                <InputLabel id="sovId-label">Schedule of Value ID</InputLabel>
                <Select
                  labelId="sovId-label"
                  multiple
                  size="small"
                  margin="dense"
                  value={sovIdLabels}
                  onChange={(e:any) => handleSovIdChange(e)}
                  input={<OutlinedInput label="Schedule of Value ID" />}
                  renderValue={(selected:any) => selected?.join(', ')}
                >
                  {autocompleteOptions?.["response"]?.map((option:any) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={sovIdLabels.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: 220 }}>
              <InputLabel id="increase-type-label">Select Increase Type</InputLabel>
                <Select
                  labelId='increase-type-label'
                  value={increaseType}
                  onChange={(e: any) => setIncreaseType(e.target.value)}
                  size="small"
                  sx={{width: 220 }}
                  input={<OutlinedInput label="Select Increase Type" />}
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  <MenuItem value="Cost Distribution">Cost Distribution</MenuItem>
                  <MenuItem value="Cost Type">Cost Type</MenuItem>
                  <MenuItem value="Percent">Percent</MenuItem>
                  <MenuItem value="Lump Sum">Lump Sum</MenuItem>
                </Select>
              </FormControl>
            </Box>
              
            </Grid>
            
            <Grid item sx={{display: "flex", flexDirection: "row"}}>

              {(increaseType === "Cost Distribution" || increaseType === "Cost Type") &&
              <Autocomplete
                sx={{minWidth: 220, width: "auto" }}
                options={increaseType === "Cost Distribution" ? costDistributionOptions : costTypes || []}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField
                      {...params}
                      label={increaseType}
                      variant="outlined"
                      size="small"
                    />
                )}
                value={selectedValue || ""}
                onChange={(event, newValue) => setSelectedValue(newValue || "")}
              /> }
              <TextField
                sx={{width: 220 }}
                label={increaseType === "Percent" ? "Percent (%)" : "$/Unit"}
                type="number"
                size="small"
                value={price}
                onChange={(e:any) => setPrice(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{ marginLeft: 1 }}
                        variant="text"
                        onClick={handleCreateIncreaseItem}
                      >
                        Create
                      </Button>
                    </InputAdornment>
                  ),
                }}
              /> 
            </Grid> 
          </Grid>}
      
          {/* Data Grid */}
          <Grid item xs={12} sx={{height: "400px", maxHeight: "400px"}}>
            <DataGridPremium
              sx={{
                fontSize: 14, 
                fontWeight: 500, 
                border: "1px solid rgba(0,0,0,0.25)"
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    created_at: false,
                    created_by: false,
                    updated_at: false,
                    updated_by: false
                  },
                },
              }} 
              checkboxSelection
              disableRowSelectionOnClick
              editMode="row"
              getRowId={row => row?._id}
              density="compact"
              rows={rows || []}
              columns={step?.column || []}
              loading={isLoading}
              rowHeight={60}
              rowBuffer={10}
              rowModesModel={rowModesModel}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleSelectionModelChange}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ clipboardPaste: true }}
              slots={{
                toolbar: RevisionToolbar,
              }}
              slotProps={{
                toolbar: {setNumRowsToAdd, numRowsToAdd, addMultipleItems, selectedColumn, handleColumnChange, step, newValue, handleValueChange, updateSelectedRows, clearRow},
              }}
              //onBeforeClipboardPasteStart={handleBeforePaste}
              // onClipboardPasteStart={handlePasteStart}
              // onClipboardPasteEnd={handlePasteEnd}
            />
          </Grid>

          <Grid item xs={12} sx={{justifyContent: "flex-end"}}>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              {!completed[activeStep] &&
              <Button sx={{marginLeft: 1}} variant="contained" onClick={handleSubmit}  component="label">
                  Save items
              </Button>}
              {completed[activeStep] &&
              <Button sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
                  Close
              </Button>} 
            </Box>
            
          </Grid>
        </Grid>
      </>

      {(isError) && 
      <Grid container justifyContent="flex-start" alignItems="center" sx={{mt: 2}} spacing={1}>
        {formErrors ? (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
            <ul>
                {Object.entries(formErrors).map(([key, value]:any) => (
                    <li key={key}>{value}</li>
                ))}
            </ul>
          </Grid>
        ) : (
          <Grid item sm={12} xs={12} sx={{fontSize: 12, color: "red"}}>
              {message}
          </Grid>
        ) }
      </Grid>}


    </Grid>
  );
};

export default RevisionGrid;
