import React from 'react';
import { Box, useTheme } from '@mui/material';
import {ReactComponent as NotFoundImage} from '../../assets/404.svg'; 

const NotFound = () => {
    const theme = useTheme();

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            style={{ height: '100%', width: '100%' }}  
        >
            <Box 
                sx={{ 
                    width: '100%',  
                    height: '100%',  
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <NotFoundImage 
                    style={{ 
                        maxWidth: '100%', 
                        maxHeight: '100%', 
                        objectFit: 'contain',
                        fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main  
                    }} 
                />
            </Box>
        </Box>
    );
};

export default NotFound;
