import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import CustomButton from './CustomButton';
import { useUploadDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';

const FileUploadButton = (props:any) => {
    const {_id, startId} = props;
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { project_id, budget_id, proposal_id, contract_id, start_id } = useParams();
    const dispatch = useAppDispatch();

    const [uploadData] = useUploadDataMutation();

    useEffect(() => {
        return () => { setSelectedFile(null) }
    },[]);
    
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event:any) => {
        const files = event.target.files;
        if (files.length > 0) {
        setSelectedFile(files[0]);
        }
    };

    const handleImport = async () => {
        try {
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                let idType = "";
                if (_id) {
                    idType = "Item Document";
                } else if (start_id) {
                    idType = "Start Document";
                } else if (budget_id) {
                    idType = "Budget Document";
                } else if (proposal_id) {
                    idType = "Proposal Document";
                } else if (contract_id) {
                    idType = "Contract Document";
                } 

                if(idType) {formData.append('idType', idType)}
                if (project_id) formData.append('project_id', project_id);
                if (budget_id) formData.append('budget_id', budget_id);
                if (proposal_id) formData.append('proposal_id', proposal_id);
                if (contract_id) formData.append('contract_id', contract_id);
                if (startId || start_id) formData.append('start_id', startId || start_id);
                if (_id) formData.append('_id', _id);

                
                const response = await uploadData({
                    tagName: "/documents",
                    body: formData,
                    url: `/documents`
                }).unwrap();

                dispatch(showSnackbar({message: response?.message || "File uploaded successfuly", type: "success"}));
                setSelectedFile(null); //Clear file from buffer
            }
        } catch (error:any) {
            const errorMessage = `Error status: ${error?.status} - ${error?.data?.message}`;
            dispatch(showSnackbar({ message: errorMessage || 'Data creation failed. Please try again', type: 'error' }));
        }
    };

    return (
        <>
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="application/pdf, image/*"
        />
        { !selectedFile &&    
        <CustomButton size="small" variant="contained" color="primary" onClick={handleButtonClick}>
            Select File
        </CustomButton>}
        { selectedFile &&
        <CustomButton
            variant="contained"
            color="success"
            size="small"
            onClick={handleImport}
            disabled={!selectedFile}
            sx={{color: "white"}}
        >
            Upload
        </CustomButton>}
        </>
    );
};

export default FileUploadButton;


