import { Tooltip, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { formatDateString, formatDateStringWithTime } from "../../../utils/formatDateString";

export const PlpColumns:GridColDef[] = [
  {
    field: 'job_type',
    headerName: 'Job Type',
    type: 'string',
    sortable: true,
    width: 120,
    editable: true,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        arrow
        title={
          //format(parseISO(params.value), 'PPPp')
          <React.Fragment>
            <Box>Created At: {formatDateString(params.row.created_at)}</Box>
            <Box>Created By: {params.row.created_by}</Box>
            <Box>Updated At: {formatDateString(params.row.updated_at)}</Box>
            <Box>Updated By: {params.row.updated_by}</Box>
          </React.Fragment>
        }
        followCursor
      >
        <Box>{params.value}</Box>
      </Tooltip>
    ),
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'number_of_prebuilt_orders',
    headerName: 'Number of Pre-Built Orders',
    type: 'number',
    sortable: true,
    width: 120,
    editable: false,
  },
];

export const plpItemColumns:GridColDef[] = [
  {
    field: 'part_number',
    headerName: 'Part Number',
    type: 'string',
    sortable: true,
    width: 160,
    editable: false,
  },
  {
    field: 'part_description',
    headerName: 'Part Description',
    type: 'string',
    sortable: true,
    width: 300,
    editable: false,
    valueGetter: (params:any) => {
      return params.row?.part_description?.part_description || params.row?.part_description ||  ''
    
    },
  },
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
  {
    field: 'prebuilt_quantity',
    headerName: 'Quantity',
    type: 'number',
    sortable: true,
    width: 80,
    editable: true,
  },
  {
    field: 'segment_one',
    headerName: 'Seg One',
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
  {
    field: 'segment_two',
    headerName: 'Seg Two',
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
];

export const orderColumns:GridColDef[] = [  
  {
    field: 'order_number',
    headerName: 'Order #',
    type: 'number',
    sortable: true,
    width: 120,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    sortable: true,
    width: 120,
    editable: true,
  },
  { 
    field: 'created_at', 
    headerName: 'Created At', 
    type: 'Date', 
    width: 220, 
    valueGetter: (params:any) => formatDateStringWithTime(params.value),
    editable: false, 
    filterable: true 
  },
  { 
    field: 'created_by', 
    headerName: 'Created By', 
    type: 'string', 
    width: 220, 
    editable: false, 
    filterable: true 
  },
];

export default PlpColumns;