import React, { useEffect, useMemo, useState } from "react";
import { Grid, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGridPremium, GridActionsCellItem, GridRowId, GridRowParams } from "@mui/x-data-grid-premium";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import documentColumns from "./documentColumns";
import DocumentViewer from "./DocumentViewer";
import { useDeleteDataMutation, useGetDataQuery } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import { useAppDispatch } from "../../../app/hooks";
import FileUploadToolbar from "../data-grid/toolbars/FileUploadToolbar";

export const buildQueryParams = (params:any) => {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      queryParams.append(key, params[key]);
    }
  });
  return queryParams.toString() ? `?${queryParams.toString()}` : '';
};

const DocumentView = ({context, _id}:any) => {
  const theme = useTheme();
  const {project_id, budget_id, contract_id, proposal_id, start_id} = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [filedId, setFileId] = useState<string>("");
  const [contentType, setContentType] = useState<string>("");
  const dispatch = useAppDispatch();
  const queryParams = useMemo(() => ({ url: "/documents", tagName: "/documents", params: { project_id, _id, budget_id, contract_id, proposal_id, start_id} }), 
  [project_id, _id, budget_id, contract_id, proposal_id, start_id]);

  const {data: documents, isLoading, status, isError, isSuccess, error} = useGetDataQuery(queryParams);
  const [deleteData] = useDeleteDataMutation();

   useEffect(() => {
    if (isError && error) {
      const errorMessage = `Document retrieval failed`;
      dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
    } else if (isSuccess && documents) {
      dispatch(showSnackbar({ message: documents?.message || 'Successfully retrieved documents', type: 'success' }));
    }
  }, [isError, error, status, isSuccess, documents, dispatch]);

  const editColumn = {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 30,
    cellClassName: 'actions',
    getActions: (params:GridRowParams) => {
    const {id} = params;
      return [
        <GridActionsCellItem
          icon={<DeleteForeverSharpIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleDeleteDocument(id)}
          color="primary"
        />  
      ];
    },
  };

  const currentColumns = useMemo(() => {
    return [editColumn, ...documentColumns || []]; // Use default columns if not found
  }, [documentColumns]);

  const handleRowDoubleClick = (params:any) => {
    setOpen((open:boolean) => !open);
    setFileId(params?.row?._id);
    setContentType(params?.row?.contentType);
  }

  const handleDeleteDocument = (id: GridRowId) => async () => { 
    await deleteData({
      tagName: "/documents",
      url: `/documents/${id}`
    }); 
  }
  
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" sx={{height: "100%"}}>
        <DocumentViewer fileId={filedId} open={open} contentType={contentType} setOpen={setOpen} />
        <Grid item sm={12} xs={12} sx={{height: "100%"}}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              opacity: 0.9,
              border: "1px solid rgba(255,255,255,0.5)",
              '& .MuiDataGrid-row:hover': {
                backgroundColor: theme.palette.primary.main, 
                opacity: "0.8"
              },
            }}
            editMode="row"
            getRowId={(row) => row._id}
            onRowDoubleClick={(params, event) => handleRowDoubleClick(params)}
            density="compact"
            rows={documents || []}
            columns={currentColumns || []}
            loading={isLoading}
            rowHeight={60}
            rowBuffer={10}
            slots={{ 
              toolbar: FileUploadToolbar,
            }}
            slotProps={{toolbar: {context, _id}}}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DocumentView;

