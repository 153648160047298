import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import styles from '../project-detail/detail.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Steps from "../../components/stepper/Steps";
import ContractCols from "../../components/columns/ContractCols";
import ProposalCols from "../../components/columns/ProposalCols";
import { BudgetDetailColumns } from "../../components/columns/fndBudgetColumns";
import ItemView from "./ItemView";
import ComparisonView from "./sov-comparison/ComparisonView";
import DocumentView from "../../components/document-viewer/DocumentView";
import CustomSnack from "../../components/snackbar/CustomSnack";
import specificationColumns from "../../components/columns/specificationColumns";
import ProposalHeader from "../proposals/ProposalHeader";
import ContractHeader from "../contracts/ContractHeader";
import sovColumns from "../../components/columns/sovColumns";

const SelectGrid = () => {
  const location = useLocation();
  const {selectedItem, isLoading, isSuccess, isError, message} = useAppSelector((state:any) => state.api);
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [snackMessage, setSnackMessage] = useState('');
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);


  useEffect(() => {
    setSnackMessage(message)
  },[message, isError, isSuccess]);

  const comparisonKeys = [
    "proposal_reference",
    "budget_reference"
  ];
  
  const path:string = "/" + location.pathname.split("/")[3];

  const routeContext: Record<string, any[]> = {
    '/budgets': [
      { index: 0, label: "Budget Items", header: ContractHeader, columns: BudgetDetailColumns, steps: Steps?.budgetItemCreation, documents: true, ref: false },
      { index: 1, label: "", header: ContractHeader, columns: [], steps: [], documents: true, ref: false },
      { index: 2, label: "", header: ContractHeader, columns: [], steps: [], documents: true, ref: false }
    ],
    '/proposals': [
      { index: 0, label: "Proposal Items", header: ProposalHeader, columns: ProposalCols?.ProposalDetailColumns, steps: Steps?.proposalItemCreation, documents: true, ref: true },
      { index: 1, label: "", header: ProposalHeader, columns: [], steps: [], documents: true, ref: true },
      { index: 2, label: "", header: ProposalHeader, columns: [], steps: [], documents: true, ref: true }
    ],
    '/contracts': [
      { index: 0, label: "Contract Items", header: ContractHeader, columns: ContractCols?.ContractDetailColumns, steps: Steps?.contractItemCreation, documents: true, ref: true },
      { index: 1, label: "", header: ContractHeader, columns: [], steps: [], documents: true, ref: true },
      { index: 2, label: "", header: ContractHeader, columns: [], steps: [], documents: true, ref: true }
    ],
    '/sov': [
      { index: 0, header: undefined, columns: sovColumns?.sovColumns, steps: [], ref: false },
    ]
  };

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    console.log(newValue)
  }, []);

  const hasReference = () => {
    for (const [key, _] of Object.entries(selectedItem || {})) {
      if (comparisonKeys.includes(key)) {
        return true;
      }
    }
    return false;
  };

  const toggleHeaderVisibility = () => {
    setIsHeaderVisible((current:boolean) => !current);
  }

  const context: any = routeContext?.[path]?.find((c: any) => c?.index === tabIndex);

  return (
    <>
    <Grid container justifyContent="center" alignItems="center" className={styles.outer}>
      {/* Header */}
       {context?.header &&
       <>
        <context.header isHeaderVisible={isHeaderVisible} />
        <Grid item xs={12} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
            <IconButton size="large" color="primary" onClick={toggleHeaderVisibility}>
              {isHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
       </>
      }
      <Grid item xs={12} justifyContent="start" className={styles.tabs}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab sx={{ fontWeight: 600 }} label="Items" value={0} />
          {hasReference() && <Tab sx={{ fontWeight: 600 }} label="Reference Comparison" value={1} />}
          {context?.documents && <Tab sx={{ fontWeight: 600 }} label="Documents" value={2} />}
        </Tabs>
      </Grid>

      {tabIndex === 0 && <ItemView context={context} path={path} />}

      {tabIndex === 1 && hasReference() && (
        <ComparisonView
          selectedContract={selectedItem?._id}
          selectedProposal={selectedItem?.proposal_reference || selectedItem?.budget_reference}
        />)}

      {tabIndex === 2 && context?.documents && (
        <Grid item sx={{height: "800px", width: "100%", padding: "0 1rem 1rem 1rem"}}>
          <DocumentView context={context} path={path} />
        </Grid>
      )}
    </Grid>
    <CustomSnack status="success" message={snackMessage} />
  </> 
  );
};

export default SelectGrid;
