import { GridColDef } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

const ContractProposalComparison: GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 160,
    editable: false,
  },
  {
    field: 'proposal_price',
    headerName: 'Total Proposal Price',
    type: 'number',
    width: 180,
    editable: false,
    valueGetter: (params:any):string => {
      return(
        params?.value ? 
        `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`
        :
        'NIP'
      ) 
    },
  },
  {
    field: 'contract_price',
    headerName: 'Total Contract Price',
    type: 'number',
    width: 180,
    editable: false,
    valueGetter: (params:any):string => {
      return(
        params?.value ? 
        `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`
        :
        'NIC'
      ) 
    },
  },
  {
    field: 'delta',
    headerName: 'Delta',
    type: 'number',
    width: 160,
    editable: false,
    renderCell: (params) => {
      const delta = Number(params.value);
      const color = (delta !== 0) ? "red" : "black";

      return (
        <Typography variant="body2" style={{fontWeight: 600, color}}>
          {isNaN(delta) ? 
          "N/A" 
          : 
          `$${Math.abs(params.value)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
          }
        </Typography>
      );
    }
  },
];

const ProposalBudgetComparison: GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 160,
    editable: false,
  },
  {
    field: 'total_cost',
    headerName: 'Total Budget Cost',
    type: 'number',
    width: 180,
    editable: false,
    renderCell: (params) => {
      const color = (params.value) ? "rgba(0,0,0,0.8)" : "red";

      return (
        <Typography variant="body2" style={{fontWeight: 600, color}}>
          {isNaN(params.value) ? 
          "NIB" 
          : 
          `$ ${Math.abs(params.value)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
          }
        </Typography>
      );
    }
  },
  {
    field: 'proposal_price',
    headerName: 'Total Proposal Price',
    type: 'number',
    width: 180,
    editable: false,
    valueGetter: (params:any):string => {
      return(
        params?.value ? 
        `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`
        :
        'NIP'
      ) 
    },
  },
  {
    field: 'gross_margin_dollar',
    headerName: 'GM ($)',
    type: 'string',
    width: 120,
    editable: false,
    renderCell: (params) => {
      const delta = Number(params.value);
      const color = (delta > 0) ? "green" : "red";

      return (
        <Typography variant="body2" style={{fontWeight: 600, color}}>
          {isNaN(delta) ? 
          "N/A" 
          : 
          `$ ${Math.abs(params.value)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
          }
        </Typography>
      );
    }
  },
  {
    field: 'gross_margin_percent',
    headerName: 'GM (%)',
    type: 'string',
    width: 80,
    editable: false,
    renderCell: (params) => {
      const percent = Number(params.value);
      const color = (percent > 0) ? "green" : "red";

      return (
        <Typography variant="body2" style={{fontWeight: 600, color}}>
          {isNaN(percent) ? 
          "N/A" 
          : 
          `${Math.abs(params.value)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })} %`
          }
        </Typography>
      );
    }
  },
];

export default {ContractProposalComparison, ProposalBudgetComparison};
