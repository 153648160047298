import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import CustomSnack from "../../components/snackbar/CustomSnack";
import styles from '../project-detail/detail.module.css';
import ContractCols from "../../components/columns/ContractCols";
import ProposalCols from "../../components/columns/ProposalCols";
import Steps from "../../components/stepper/Steps";
import { useLocation } from "react-router-dom";
import { apiRouteMap } from "../../routes/urls";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { BudgetItemColumns } from "../../components/columns/fndBudgetColumns";
import sovColumns from "../../components/columns/sovColumns";
import { plpItemColumns } from "../../components/columns/plpColumns";
import { useDeleteDataMutation, useUpdateDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";

function ExpandedContent(props:any) {
    const {job_type, row, items} = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const path = "/" + location.pathname.split("/")[3];
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [rows, setRows] = useState<any>([]);

    const [updateData] = useUpdateDataMutation();
    const [deleteData] = useDeleteDataMutation();

    const sovCols =  sovColumns?.useSovItemColumns();

    const routeContext = [
      {
        index: 0, id: '/budgets',  columns: BudgetItemColumns, steps: Steps?.budgetItemCreation, isEditable: true,
      },
      {
        index: 1, id: '/proposals', columns: ProposalCols?.ProposalItemColumns, steps: Steps?.proposalItemCreation, isEditable: true,
      },
      {
        index: 2, id: '/contracts', columns: ContractCols?.ContractItemColumns, steps: Steps?.contractItemCreation, isEditable: true,
      },
      {
        index: 3, id: '/sov',  columns: sovCols, steps: [], isEditable: true,
      }, 
      {
        index: 4, id: '/plps',  columns: plpItemColumns, steps: [], isEditable: false,
      }, 
    ];
    const context = routeContext.find((context:any) => context?.id === path);

    const editColumn = useMemo(() => ({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 160,
      cellClassName: 'actions',
      getActions: (props:any) => {
      const {id} = props;
      if(rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit){
          return [
            <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                color: 'primary.main',
                }}
                onClick={handleSaveLot(id)}
            />,
            <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="primary"
            />
          ];   
        }
        return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditLot(id)}
                color="primary"
            />,
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleDeleteItem(id)}
                color="primary"
            />  
        ];
      },
    }),[]);
    
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleSaveLot = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleEditLot = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    
    const handleDeleteItem = (id: GridRowId) => async () => {
      try{
        const response = await deleteData({
            tagName: path,
            url:  apiRouteMap.get(`${path}/items`)?.delete(id)
        }).unwrap(); 
        dispatch(showSnackbar({ message: response?.message || "Item deleted", type: 'success' }));
      }catch(error:any){
        dispatch(showSnackbar({ message: error?.message, type: 'error' }));
      }
    }
        
    //Need to update this
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row:any) => row.item_id === id);
      if (editedRow && editedRow!.isNew) {
        setRows(rows.filter((row:any) => row.item_id !== id));
      }
    };
    
    const processRowUpdate = useCallback(async (newRow: any) => {
      try {
        const data = job_type ? {  job_type, ...newRow } : newRow;
        const response = await updateData({
          tagName: path,
          body:  data,
          url: newRow?.job_type ? 
            apiRouteMap.get(`${path}/items`).update(newRow?.item_id, newRow?.job_type)
            :
            apiRouteMap.get(`${path}/items`).update(newRow?.item_id)
        }).unwrap();
        dispatch(showSnackbar({ message: response?.message, type: 'success' }));
        return newRow;
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));
      } 
    }, []);
  
    const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
      setRowModesModel(rowModesModel);
    }

    const cols = (context?.id !== "/budgets" && context?.isEditable) ? [editColumn, ...context?.columns || []] : context?.columns;

    return (
    <Grid container justifyContent="center" alignItems="center" sx={{width: "100%"}}>
        <DataGridPro
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)",
            padding: "1rem",
            maxHeight: "300px"
          }}
          key={row}
          className={styles.gridStyle}
          columns={cols || []}
          rows={items || []}
          editMode="row"
          getRowId={row => row?.item_id || row?._id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
        />
    </Grid>
  );
}

export default ExpandedContent;


  