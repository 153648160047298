import React, { useState } from 'react';
import { Button, Menu, MenuItem, Grid } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { getCiImportByStartIds, getJCReportByStartIds, getOrdProcEnt } from '../../reports/reportSlice';
import CustomButton from './CustomButton';

const  DropdownMenuButton = (props:any) => {
    const dispatch = useAppDispatch();
    const {exportItems, tabIndex} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (item:string) => {
        switch(item){
            case 'JC Report':
                for(const item of exportItems){
                    dispatch(getJCReportByStartIds({
                        service: "app",
                        fileName: `${item?.job_number}-${item?.subjob_number}-jc-report`?.toUpperCase(),
                        url: `start_id=${item?._id}`
                    })) 
                }
            break;
            case 'CI Import':
                for(const item of exportItems){
                    dispatch(getCiImportByStartIds({
                        service: "app",
                        fileName: `${item?.job_number}-${item?.subjob_number}-ci-import`?.toUpperCase(),
                        url: `start_id=${item?._id}`
                    }))
                }
            break;
                case 'OrdProcEnt':
                    for(const item of exportItems){
                        dispatch(getOrdProcEnt({
                            service: "app",
                            fileName: `${item?.job_number}-${item?.subjob_number}-OrdProcEnt`?.toUpperCase(),
                            url: `start_id=${item?._id}`
                        }))
                    }
                break;
            default:
                break;
        }
        handleClose();
    };


    return (
        <Grid item xs={12}>
            {!tabIndex === undefined && 
            <>
                <Button
                    sx={{fontWeight: 600, width: "auto"}}
                    size="small"
                    variant="text"
                    onClick={(e:any) => handleClick(e)}
                    component="label"
                >
                    Export Report
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleMenuClick('JC Report')}>JC Report</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('CI Import')}>CI Import</MenuItem>
                    <MenuItem onClick={() => handleMenuClick('OrdProcEnt')}>OrdProcEnt</MenuItem>
                </Menu>
            </>
            }
            {tabIndex === 0 &&
            <CustomButton onClick={() => handleMenuClick('JC Report')} size="small" color="primary" variant='text'>JC Report</CustomButton>
            }
            {tabIndex === 1 &&
            <CustomButton onClick={() => handleMenuClick('CI Import')} size="small" color="primary" variant='text'>CI Import</CustomButton>
            }
            {tabIndex === 2 &&
            <CustomButton onClick={() => handleMenuClick('OrdProcEnt')} size="small" color="primary" variant='text'>OrdProcEnt</CustomButton>
            }

        </Grid>
    );
}

export default DropdownMenuButton;