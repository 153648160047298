import { GridColDef } from "@mui/x-data-grid-premium";

export const BudgetDetailColumns:GridColDef[] = [
  { field: 'plan', headerName: 'Plan', type: 'string', width: 160, editable: false, filterable: true },
  { field: 'elevation', headerName: 'Elevation', type: 'string', width: 160, editable: false, filterable: true },
  { field: 'option', headerName: 'Option', type: 'string', width: 150, editable: false, filterable: true },
  { field: 'option_code', headerName: 'Option Code', type: 'string', width: 130, editable: false, filterable: true },
  { 
    field: 'total_cost',
    headerName: 'Total Cost',
    type: 'number',
    width: 130,
    editable: false,
    filterable: true,
    valueGetter: (params:any):string => {
      return `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
  }, 
  },
  
];

export const BudgetItemColumns:GridColDef[] = [
  { 
    field: 'cost_distribution',
    headerName: 'Cost Distribution',
    type: 'string',
    width: 150,
    editable: false,
    filterable: true },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    type: 'string',
    width: 120,
    editable: true,
  },
  { field: 'part_number', headerName: 'Part Number', type: 'string', width: 150, editable: false, filterable: true },
  { field: 'description', headerName: 'Description', type: 'string', width: 200, editable: false, filterable: true },
  { field: 'total_amount', headerName: 'Total Amount', type: 'number', width: 120, editable: false, filterable: true },
  { field: 'takeoff_quantity', headerName: 'Takeoff Quantity', type: 'number', width: 160, editable: false, filterable: true },
  { field: 'takeoff_unit', headerName: 'Takeoff Unit', type: 'string', width: 120, editable: false, filterable: true },
  { field: 'labor_productivity', headerName: 'Labor Productivity', type: 'number', width: 160, editable: false, filterable: true },
  { field: 'labor_unit_per_hour', headerName: 'Labor Unit/Hour', type: 'string', width: 150, editable: false, filterable: true },
  { field: 'labor_cost_per_unit', headerName: 'Labor Cost/Unit', type: 'number', width: 150, editable: false, filterable: true },
  { field: 'labor_cost_unit', headerName: 'Labor Cost Unit', type: 'string', width: 130, editable: false, filterable: true },
  { field: 'labor_quantity', headerName: 'Labor Quantity', type: 'number', width: 140, editable: false, filterable: true },
  { field: 'labor_per_hour', headerName: 'Labor/Hour', type: 'string', width: 120, editable: false, filterable: true },
  { field: 'labor_cost_per_hour', headerName: 'Labor Cost/Hour', type: 'number', width: 150, editable: false, filterable: true },
  { field: 'labor_per_hour2', headerName: 'Labor Cost/Hour 2', type: 'string', width: 150, editable: false, filterable: true },
  { field: 'material_conv_factor', headerName: 'Material Conversion Factor', type: 'number', width: 200, editable: false, filterable: true },
  { field: 'material_conv_unit', headerName: 'Material Conversion Unit', type: 'string', width: 200, editable: false, filterable: true },
  { field: 'material_quantity', headerName: 'Material Quantity', type: 'number', width: 120, editable: false, filterable: true },
  { field: 'material_price', headerName: 'Material Price', type: 'number', width: 120, editable: false, filterable: true },
  { field: 'material_unit', headerName: 'Material Unit', type: 'string', width: 130, editable: false, filterable: true },
  { field: 'material_cost_unit', headerName: 'Material Cost Unit', type: 'string', width: 160, editable: false, filterable: true },
  { field: 'sub_productivity', headerName: 'Sub Productivity', type: 'string', width: 120, editable: false, filterable: true },
  { field: 'sub_per_hour', headerName: 'Sub/Hour', type: 'string', width: 120, editable: false, filterable: true },
  { field: 'sub_cost_per_unit', headerName: 'Sub Cost/Unit', type: 'string', width: 140, editable: false, filterable: true },
  { field: 'labor_update', headerName: 'Labor Update', type: 'string', width: 140, editable: false, filterable: true },
  { field: 'rock_concrete', headerName: 'Rock Concrete', type: 'string', width: 140, editable: false, filterable: true },
];

export const BudgetColumns:GridColDef[] = [
  {
    field: 'job_type',
    headerName: 'Job Type',
    type: 'string',
    width: 160,
    editable: true,
    filterable: true,
  },
  {
    field: 'budget_rev_no',
    headerName: 'Budget Revision',
    type: 'string',
    width: 120,
    editable: true,
    filterable: true,
  },
  {
    field: 'budget_name',
    headerName: 'Budget Name',
    type: 'string',
    width: 160,
    editable: true,
    filterable: true,     
  },
  {
    field: 'good_through_date',
    headerName: 'Good Through Date',
    width: 160,
    type: 'date',
    headerAlign: 'left',
    sortable: true,
    editable: true,
    valueGetter: (params:any) => params.value ? new Date(params.value) : null,
  },
  {
    field: 'active_release',
    headerName: 'Active Release',
    type: 'string',
    width: 120,
    editable: true,
    filterable: true,   
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 120,
    editable: true,
    filterable: true
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    type: 'date',
    width: 180,
    filterable: true,
    editable: false,
    valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
    valueFormatter: (params: any) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
  },
  {
      field: 'created_by',
      headerName: 'Created By',
      type: 'string',
      width: 150,
      filterable: true,
      editable: false,
  },
  {
      field: 'updated_at',
      headerName: 'Updated At',
      type: 'date',
      width: 180,
      filterable: true,
      editable: false,
      valueGetter: (params:any) => params.value ? new Date(params.value) : new Date(),
      valueFormatter: (params: any) => {
          if (!params.value) {
            return '';
          }
          const date = new Date(params.value);
          return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
  },
  {
      field: 'updated_by',
      headerName: 'Updated By',
      type: 'string',
      width: 150,
      filterable: true,
      editable: false,
  }
];