import { GridColDef } from '@mui/x-data-grid';

const ordProcEntColumns: GridColDef[] = [
    { field: 'order_id', headerName: 'ORDER ID', width: 200 },
    { field: 'part_number', headerName: 'PART NUMBER', width: 150 },
    { field: 'unit_of_measure', headerName: 'UNIT OF MEASURE', width: 150 },
    { field: 'order_quantity', headerName: 'ORDER QUANTITY', width: 150 },
    { field: 'part_description', headerName: 'PART DESCRIPTION', width: 300 },
    { field: 'cost_code', headerName: 'COST CODE', width: 150 },
    { field: 'cost_type', headerName: 'COST TYPE', width: 100 },
    { field: 'order_number', headerName: 'ORDER NUMBER', width: 150 },
    { field: 'company_number', headerName: 'COMPANY NUMBER', width: 150 },
    { field: 'division_number', headerName: 'DIVISION NUMBER', width: 150 },
    { field: 'job_number', headerName: 'JOB NUMBER', width: 150 },
    { field: 'subjob_number', headerName: 'SUBJOB NUMBER', width: 150 },
  ];

export default ordProcEntColumns;