import React, { useCallback, useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { DataGridPremium, GridActionsCellItem, GridRowSelectionModel, GridFilterModel, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel } from "@mui/x-data-grid-premium";
import { deleteDoc, getListItems, updateDoc } from "../../api/apiSlice";
import columnMap from "../columns/columns";
import { useLocation} from "react-router-dom";
import { apiRouteMap } from "../../routes/urls";
import styles from "./gridView.module.css";
import { MenuItem, Select, Button, TextField, Grid, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Steps from "../stepper/Steps";
import CustomGridToolbar from "./toolbars/CustomGridToolbar";


const GridView = () => {
  const {isAuthenticated} = useAppSelector((state:any) => state.auth);
  const {message, isSuccess, isLoading, isError} = useAppSelector((state: any) => state.api);
  const [rows, setRows] = useState<any>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  const [dialog, openDialog] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedColumn, setSelectedColumn] = useState("");
  const [newValue, setNewValue] = useState("");
  const [currentColumns, setCurrentColumns] = useState<any[]>([]);
  const [snackMessage, setSnackMessage] = useState('');
  const [paginationModel, setPaginationModel] = useState<any>({
    pageSize: 100,
    page: 0,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState<any>({});
  const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>();

  const context = [
    {
      id: '/concrete/mix-design-pricings', index: 0, getUrl: () => `fnd-concrete-pricings`, ref: 'data', grouped: false, steps: Steps?.importConcretePricing
    },
    {
      id: '/concrete/aggregate-pricing', index: 1, getUrl: () => `fnd-aggregate-pricings`, ref: 'data', grouped: false, steps: Steps?.importAggregatePricing
    },
    {
      id: '/warehouse-orders', index: 2, getUrl: () => `warehouse/orders`, ref: 'data', grouped: false, steps: []
    },
  ];

  const currentContext = context?.find((c:any) => c?.id === location.pathname);
  const editColumn = {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 80,
    cellClassName: 'actions',
    getActions: (props:any) => {
    const {id} = props;
    if(rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit){
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
          color: 'primary.main',
          }}
          onClick={handleSave(id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancel(id)}
          color="primary"
        />
      ];   
    }
    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEdit(id)}
        color="primary"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleDelete(id)}
        color="primary"
      />  
    ];
  },
  };

  useEffect(() => {
    const cols = columnMap?.get(location.pathname)?.staticCols;
    setCurrentColumns([editColumn, ...cols])
  },[columnMap]);
  
  const fetchGridData = useCallback(async (signal:AbortSignal) => {
    try {
      // Build filter query string if filters are applied
      const filterParams = filterModel?.items
        ?.map((item:any) => `${encodeURIComponent(item.field)}=${encodeURIComponent(item.value)}`)
        ?.join('&');
      
      const queryParams = `?page=${paginationModel.page + 1}&limit=${paginationModel.pageSize}${(filterModel?.items?.length > 0) ? `&${filterParams}` : ''}`;
      const url = `${apiRouteMap.get(location.pathname).url}${queryParams}`;
      console.log(url);
      const response = await dispatch(getListItems({ service: "app", url }));

      if (response?.payload) {
        setRows(response.payload.data);
        setTotalRowCount(response.payload.total);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error("Fetch error:", error);
      } else {
        console.error("Fetch aborted.");
      }
    }
  }, [dispatch, paginationModel, filterModel, location]);

  const handleRefresh = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if(isAuthenticated){
      //await fetchStart(signal);
      await fetchGridData(signal);
    }
    return () => {
        abortController.abort();
    };
  }

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setPaginationModel({ ...paginationModel, page: 0 }); // Reset to first page
      setFilterModel(newFilterModel);

    }, 600);
    setFilterTimeout(newTimeout);
  }, [filterModel, 600]);


  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      if(isAuthenticated) await fetchGridData(signal);
    }
    fetchData();
    // Setup polling, fetch data every minute
    const interval = setInterval(fetchData, 60000);
    return () => {
        clearInterval(interval);
        setRows([]);
        setCurrentColumns([]);
        abortController.abort();
    };
  }, [fetchGridData, isAuthenticated]);

  useEffect(() => {
    setSnackMessage(message)
  },[message, isError, isSuccess]);


  const handleColumnChange = (event:any) => {
    setSelectedColumn(event.target.value);
  };
  

  const handleValueChange = (event:any) => {
    setNewValue(event.target.value);
  };
  
  const updateSelectedRows = () => {
    let updatedRows = [...rows];
  
    rowSelectionModel.forEach((rowId:GridRowId) => {
      const rowToUpdate = updatedRows.find((row) => row._id === rowId);
      if (rowToUpdate) {
        const updatedRow = { ...rowToUpdate, [selectedColumn]: newValue };
        const processedRow = processRowUpdate(updatedRow);
        updatedRows = updatedRows.map((row) => (row._id === rowId ? processedRow : row));
      }
    });
  
    setRows(updatedRows);
  };
  
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSave = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = (id: GridRowId) => () => {
    const req:any = {
      service: "app",
      url: `${apiRouteMap.get(location.pathname).manageItem(id)}`
    }
    if(rowSelectionModel.length > 0){
      setRows([]);
      req['ids'] = rowSelectionModel;
      dispatch(deleteDoc(req));
      return;

    }
    setRows(rows.filter((row:any) => row._id !== id));
    dispatch(deleteDoc(req));
    
  }


  const handleCancel = (id: GridRowId) => () => {
    openDialog(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow && editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };
  
  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows: any) => rows.map((row: any) => (row._id === newRow._id) ? newRow : row));
    const req = {
      service: apiRouteMap.get(location.pathname).service,
      data: newRow,
      url: apiRouteMap.get(location.pathname).manageItem(newRow?._id)
    }
    dispatch(updateDoc(req));
    setRows((rows: any) => rows.map((row: any) => (row._id === newRow._id) ? newRow : row));

    return newRow;
  }, [dispatch]);

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
    let checked: any[] = [];
    rowSelectionModel.forEach((id:any) => {
      checked.push(rows.find((row:any) => row._id === id));
    });
    console.log(rowSelectionModel)
  }

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }

  return (  
    <Grid container sx={{ height: "calc(100vh-64px)"}}>
      <Grid item xs={12}>
        <Select
          value={selectedColumn}
          onChange={handleColumnChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          margin="dense"
          size="small"
          sx={{fontSize: "1rem"}}
        >
          <MenuItem value="" disabled>
            Select Column
          </MenuItem>
          {currentColumns.map((col) => (
            <MenuItem sx={{fontSize: "1rem"}} key={col.field} value={col.field}>{col.headerName}</MenuItem>
          ))}
        </Select>
        
        <TextField
          value={newValue}
          onChange={handleValueChange}
          size="small"
        />
        <Button  sx={{marginLeft: 0.5}} variant="outlined" onClick={updateSelectedRows}>Update Rows</Button>
      </Grid>
          {/* Horizontal Scrollbar Container */}
      <Box sx={{ overflowX: 'auto', display: 'flex', borderBottom: '1px solid #ccc', paddingBottom: '8px' }}>
        <Box sx={{ minWidth: '1000px' }}></Box> {/* Adjust minWidth to match DataGrid content width */}
      </Box>
      <Grid item xs={12} 
        sx={{
          height: "100%",
          
        }}>
        <DataGridPremium 
        className={styles.grid}
        sx={{
          fontSize: 14, 
          fontWeight: 500, 
          border: "1px solid rgba(255,255,255,0.5)",
          height: "100%",
          overflowY: "hidden"
        }}
        checkboxSelection 
        editMode="row" 
        getRowId={row => row._id} 
        columns={currentColumns || []} 
        rows={rows || []} 
        pagination 
        paginationMode="server"
        pageSizeOptions={[25,50,100,250]} 
        rowCount={totalRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={handleSelectionModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        density="compact" 
        loading={isLoading} 
        rowHeight={60} 
        rowBuffer={10} 
        rowModesModel={rowModesModel} 
        onRowModesModelChange={handleRowModesModelChange} 
        onRowEditStop={handleRowEditStop} 
        processRowUpdate={processRowUpdate}
        slots={{ toolbar: CustomGridToolbar }} 
        slotProps={{toolbar: {type: "stepper", context: currentContext, handleRefresh }}}
      />
      </Grid>
    </Grid>    
  );
}

export default GridView
