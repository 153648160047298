import React from 'react';
import { Card, CardActionArea, Typography, Grid, Box, useTheme, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { pages } from '../layout/PageLinks';

// Card Component
const PageCard = ({ page }:any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(page.link);
  };

  if (page?.title === "Logout" || page?.title === "Dashboard") return null;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "32rem",
        maxWidth: "100vw",
        minWidth: "12rem",
        minHeight: "12rem",
        border: "1px solid rgba(0,0,0,0.25)",
        boxShadow: "1px 1px rgba(0,0,0,0.25)",
        justifyContent: "space-between"
      }}
    >
      {/* Title */}
      <Box sx={{ width: "100%", textAlign: "center", padding: "1rem" }}>
        <Typography
          color={
            theme.palette.mode === "dark"
              ? theme.palette.primary.light
              : theme.palette.primary.main
          }
          component="div"
          variant="h6"
        >
          {page.title}
        </Typography>
      </Box>
  
      {/* Image */}
      <CardActionArea
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          width: "100%",
          padding: "1rem"
        }}
      >
        <page.imgSrc 
          style={{ 
            height: '16em', 
            width: '16em', 
            backgroundColor: "white",
            fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main  
          }}
        />
      </CardActionArea>
  
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: "100%" }}
        >
        </AccordionSummary>
  
        {/* Description */}
        <AccordionDetails sx={{ padding: "1rem", textAlign: "center" }}>
          <Typography variant="body2" color="textSecondary" component="p">
            {page.desc}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

// Main component to display cards
const PagesGrid = () => {
  return (
    <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", maxHeight: "1200px", padding: "2rem", marginBottom: 2}} gap={1}>
      {pages?.map((page:any) => (
        <Grid item xs key={page?.id} >
          <PageCard page={page} />
        </Grid>
      ))}
    </Grid>     
  );
};

export default PagesGrid;
