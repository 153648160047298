import { GridColDef } from "@mui/x-data-grid-premium";

const flatworkSfColumns:GridColDef[] = [
    
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 160,
    editable: true,
  },
  { 
    field: 'condition', 
    headerName: 'Condition',
    width: 160, 
    type: 'string' ,
    editable: true,
    filterable: true,
  },
  {
    field: 'dimension_one',
    headerName: 'Dimension 1',
    type: 'number',
    width: 120,
    editable: true,
  },  
  {
    field: 'dimension_two',
    headerName: 'Dimension 2',
    type: 'number',
    width: 120,
    editable: true,
  }, 
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    type: 'string',
    width: 120,
    editable: true,
  }, 
  {
    field: 'price',
    headerName: '$/Unit',
    type: 'number',
    width: 160,
    editable: true,
  },
  
];

  export default flatworkSfColumns;