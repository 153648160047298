import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import styles from '../../project-detail/detail.module.css';
import { Typography } from "@mui/material";
import ContractCols from "../../../components/columns/ContractCols";
import { BudgetItemColumns } from "../../../components/columns/fndBudgetColumns";
import ProposalCols from "../../../components/columns/ProposalCols";

function ExpandedContent(props:any) {
    const {row, items} = props;
    const [labels, setLabels] = useState<any>([]);

    useEffect(() => {
      let data = []
      if(items){
        for(const [key,val] of Object.entries(items)){
          const label = key.replace("_", " ").toUpperCase();
          data.push({type: key.split("_")[0], label})
        }
        setLabels(data);
      }
    },[items, row]);

    const getLabel = (type:string) => {
      const labelObj = labels.find((label:any) => label.type === type);
      return labelObj ? labelObj.label : "";
    };

    return (
    <Grid container justifyContent="center" alignItems="flex-start" className={styles.detailPanel} spacing={2}>
      <Grid item xs={12}>
        <Typography sx={{textAlign: "center", fontWeight: 500}}>{getLabel('proposal')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)",
            padding: 2,
            overflowY: "scroll",
            maxHeight: "400px"
          }}
          key={row}
          className={styles.gridStyle}
          columns={ProposalCols?.ProposalItemColumns?.map((column:any) => {
            return {...column, editable: false}}
          )}
          rows={items?.proposal_items || []}
          editMode="row"
          getRowId={row => row?.item_id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
        />
      </Grid>
      
      {items?.budget_items?.length > 0 &&
      (
      <>
        <Grid item xs={12}>
          <Typography sx={{textAlign: "center", fontWeight: 500}}>{getLabel('budget')}</Typography>
        </Grid>
        <Grid item xs={12}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)",
            padding: 2,
            overflowY: "scroll",
            maxHeight: "400px"
          }}
          key={row}
          className={styles.gridStyle}
          columns={BudgetItemColumns?.map((column:any) => {
            return {...column, editable: false}}
          )}
          rows={items?.budget_items || []}
          editMode="row"
          getRowId={row => row?.item_id || row?._id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
        />
      </Grid>
      </>
      
    )}
      {items?.contract_items?.length > 0 &&
      (
      <>
        <Grid item xs={12}>
          <Typography sx={{textAlign: "center", fontWeight: 500}}>{getLabel('contract')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              border: "1px solid rgba(255,255,255,0.5)",
              padding: 2,
              overflowY: "scroll",
              maxHeight: "400px"
            }}
            key={row}
            className={styles.gridStyle}
            columns={ContractCols?.ContractItemColumns?.map((column:any) => {
              return {...column, editable: false}}
            )}
            rows={items?.contract_items || []}
            editMode="row"
            getRowId={row => row?.item_id || row?._id}
            density="compact"
            rowHeight={60}
            rowBuffer={10}
          />
        </Grid>
      </>
    )}
    </Grid>
  );
}

export default ExpandedContent;


  