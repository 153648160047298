import React, { useEffect, useState, useMemo } from 'react';
import FormComponent from './FormComponent';
import { useAppSelector } from '../../../app/hooks';
import { useGetCompanyNumbersQuery } from '../../dropdowns/dropdownApi';
import { AutocompleteOptions } from '../../../utils/interfaces';


const DivisionForm = (props: any) => {
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const { data: company_numbers } = useGetCompanyNumbersQuery();

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});

    useEffect(() => {
        setAutocompleteOptions({
            company_id: {
                data: company_numbers?.data || [],
                options: company_numbers?.data?.map((co: any) => co.company_number) || [],
            },

            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [company_numbers, statuses]);

    return <FormComponent {...props} autocompleteOptions={autocompleteOptions} />;
};

export default DivisionForm;
