import { GridColDef } from "@mui/x-data-grid-premium";

export const JCReportColumns: GridColDef[] = [   
  {
    field: 'company_number',
    headerName: 'Company Number',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'division_number',
    headerName: 'Division Number',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'job_number',
    headerName: 'Job Number',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'subjob_number',
    headerName: 'Subjob Number',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'ecms_cost_code',
    headerName: 'Cost Distribution',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    type: 'string',
    width: 120,
    editable: false,
  }, 
  {
    field: 'budget_hours',
    headerName: 'Budget Hours',
    type: 'number',
    width: 130,
    editable: false,
  },
  {
    field: 'budget_quantity',
    headerName: 'Budget Quantity',
    type: 'number',
    width: 150,
    editable: false,
  },
  {
    field: 'budget_amount',
    headerName: 'Budget Amount',
    type: 'number',
    width: 150,
    editable: false,
    valueGetter: (params:any):string => {
      if(params.value){
          return `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
      }else{
          return '';
      }
    },
  },
  {
    field: 'unit_of_measure',
    headerName: 'Unit of Measure',
    type: 'string',
    width: 150,
    editable: false,
  },
  {
    field: 'as_bid_amount',
    headerName: 'As Bid Amount',
    type: 'number',
    width: 150,
    editable: false,
    valueGetter: (params:any):string => {
      return `$${params.value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    },
  },
];
  