import React from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import DeliveryMethodEditComponent from './edit-components/DeliveryMethodEditComponent';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp';
import { Typography } from '@mui/material';
import PendingActionsSharpIcon from '@mui/icons-material/PendingActionsSharp';
import AlarmAddSharpIcon from '@mui/icons-material/AlarmAddSharp';

const WarehouseOrderColumns: GridColDef[] = [
  {
    field: 'job_number',
    headerName: 'Job #',
    width: 80,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false,
  },
  {
    field: 'subjob_number',
    headerName: 'Subjob #',
    width: 80,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false,
  },
  {
    field: 'job_type',
    headerName: 'Job Type',
    width: 220,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false,
  },
  {
    field: 'order_number',
    headerName: 'Order Request #',
    width: 160,
    type: 'string',
    sortable: true,
    editable: false,
  },
  {
    field: 'prebuilt_order_number',
    headerName: 'Pre-Built Order #',
    width: 160,
    type: 'string',
    headerAlign: 'center',
    sortable: true,
    editable: false,
  },
  {
    field: 'number_of_lots',
    headerName: 'Number of Lots',
    width: 160,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false,
  },
  {
    field: 'delivery_method',
    headerName: 'Delivery Method',
    width: 160,
    type: 'string',
    sortable: true,
    editable: true,
    renderEditCell: (params) => <DeliveryMethodEditComponent {...params} />,
  },
  {
    field: 'order_status',
    headerName: 'Status',
    width: 120,
    type: 'string',
    sortable: true,
    editable: true,
    //renderEditCell: (params) => <StatusEditComponent {...params} />,
    renderCell: (params) => {
      let icon;
      let color;
      let text;

      switch (params.value) {
        case 'REQUESTED':
            icon = <PendingActionsSharpIcon />
            color = 'green';
            text = 'REQUESTED';
            break;
        case 'FULFILLED':
            icon = <TaskAltSharpIcon />;
            color = 'green';
            text = 'FULFILLED';
            break;
        case 'REJECTED':
            icon = <PriorityHighSharpIcon />;
            color = 'red';
            text = 'REJECTED';
            break;
        case 'STAGED':
            icon = <InventorySharpIcon />;
            color = 'green';
            text = 'STAGED';
            break;
        default:
            text = params.value;
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center', color, fontWeight: 600 }}>
          {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
          <Typography fontWeight={500} variant="body2">{text}</Typography>
        </div>
      );
    },
  },
  {
    field: 'requested_date',
    headerName: 'Requested Date',
    width: 120,
    type: 'date',
    sortable: true,
    editable: true,
    valueGetter: (params: any) => (params.value ? new Date(params.value) : null),
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 120,
    type: 'date',
    sortable: true,
    editable: false,
    valueFormatter: (params: any) => {
      if (!params.value) {
        return '';
      }
      const date = new Date(params.value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    width: 220,
    type: 'string',
    sortable: true,
    editable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 120,
    type: 'date',
    sortable: true,
    editable: false,
    valueFormatter: (params: any) => {
      if (!params.value) {
        return '';
      }
      const date = new Date(params.value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    width: 220,
    type: 'string',
    sortable: true,
    editable: false,
  },
];

const WarehouseOrderDetailColumns: GridColDef[] = [
    {
        field: 'order_number',
        headerName: 'Order Request #',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'prebuilt_order_number',
        headerName: 'Pre-Built Order #',
        width: 160,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },

    {
        field: 'delivery_method',
        headerName: 'Delivery Method',
        width: 160,
        type: 'string',
        sortable: true,
        editable: true,
        renderEditCell: (params) => <DeliveryMethodEditComponent {...params} />,
    },
    {
        field: 'order_status',
        headerName: 'Status',
        width: 120,
        type: 'string',
        sortable: true,
        editable: false,
        //renderEditCell: (params) => <StatusEditComponent {...params} />,
        renderCell: (params) => {
          let icon;
          let color;
          let text;
    
          switch (params.value) {
            case 'REQUESTED':
                icon = <PendingActionsSharpIcon />
                color = 'green';
                text = 'REQUESTED';
                break;
            case 'FULFILLED':
                icon = <TaskAltSharpIcon />;
                color = 'green';
                text = 'FULFILLED';
                break;
            case 'CREATED':
                icon = <AlarmAddSharpIcon />;
                color = 'green';
                text = 'CREATED';
                break;
            case 'REJECTED':
                icon = <PriorityHighSharpIcon />;
                color = 'red';
                text = 'REJECTED';
                break;
            case 'STAGED':
                icon = <InventorySharpIcon />;
                color = 'green';
                text = 'STAGED';
                break;
            default:
                text = params.value;
          }
    
          return (
            <div style={{ display: 'flex', alignItems: 'center', color, fontWeight: 600 }}>
              {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
              <Typography fontWeight={500} variant="body2">{text}</Typography>
            </div>
          );
        },
      },
    {
        field: 'requested_date',
        headerName: 'Requested Date',
        width: 120,
        type: 'date',
        sortable: true,
        editable: true,
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
    }, 
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 120,
        type: 'date',
        sortable: true,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 220,
        type: 'string',
        sortable: true,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 120,
        type: 'date',
        sortable: true,
        editable: false,
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        width: 220,
        type: 'string',
        sortable: true,
        editable: false,
    },
    
];

const WarehouseOrderRequestItemColumns:GridColDef[] =[ 
    {
        field: 'part_number',
        headerName: 'Part #',
        width: 160,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
        field: 'part_description',
        headerName: 'Description',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },

    {
        field: 'prebuilt_order_quantity',
        headerName: 'Pre-Built Order Quantity',
        width: 120,
        type: 'number',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
      field: 'requested_quantity',
      headerName: 'Requested Quantity',
      width: 120,
      type: 'number',
      headerAlign: 'left',
      sortable: true,
      editable: true
    },
    {
        field: 'unit_of_measure',
        headerName: 'UoM',
        width: 80,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
        field: 'segment_one',
        headerName: 'Segment One',
        width: 80,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    },
    {
        field: 'segment_two',
        headerName: 'Segment Two',
        width: 80,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false
    }
        
];
const WarehouseOrderFulfillItemColumns:GridColDef[] =[ 
  {
    field: 'part_number',
    headerName: 'Part #',
    width: 160,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },
  {
    field: 'part_description',
    headerName: 'Description',
    width: 220,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },

  {
    field: 'prebuilt_order_quantity',
    headerName: 'Pre-Built Order Quantity',
    width: 120,
    type: 'number',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },
  {
    field: 'requested_quantity',
    headerName: 'Requested Quantity',
    width: 120,
    type: 'number',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },
  {
    field: 'fulfilled_quantity',
    headerName: 'Fulfilled Quantity',
    width: 120,
    type: 'number',
    headerAlign: 'left',
    sortable: true,
    editable: true
  },
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    width: 80,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },
  {
    field: 'segment_one',
    headerName: 'Segment One',
    width: 80,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false
  },
  {
    field: 'segment_two',
    headerName: 'Segment Two',
    width: 80,
    type: 'string',
    headerAlign: 'left',
    sortable: true,
    editable: false
  }     
];

export default {
    WarehouseOrderColumns,
    WarehouseOrderDetailColumns, 
    WarehouseOrderRequestItemColumns, 
    WarehouseOrderFulfillItemColumns
}