import { GridColDef } from "@mui/x-data-grid-premium";

const SegmentOneColumns: GridColDef[] = [
    {
        field: 'segmentOne',
        headerName: 'Segment One',
        type: 'string',
        width: 120,
        editable: true,
    },
    {
      field: 'segmentOneDescription',
      headerName: 'Segment One Description',
      type: 'string',
      width: 220,
      editable: true,
    },
];
const SegmentTwoColumns:GridColDef[] = [
    {
        field: 'segmentTwo',
        headerName: 'Segment Two',
        type: 'string',
        width: 120,
        editable: true,
    },
    {
        field: 'segmentTwoDescription',
        headerName: 'Segment Two Description',
        type: 'string',
        width: 220,
        editable: true,
    },
];

const CostTypeColumns: GridColDef[] = [
    {
        field: 'costType',
        headerName: 'Cost Type',
        type: 'string',
        width: 160,
        editable: true,
    },
    {
        field: 'costTypeDescription',
        headerName: 'Cost Type Description',
        type: 'string',
        width: 160,
        editable: true,
    },
]


const CostDistributionColumns: GridColDef[] = [
    {
        field: 'costDistributionForImport',
        headerName: 'Cost Distribution For Import',
        type: 'string',
        width: 220,
        editable: true,
    },
    {
        field: 'costDistributionDescription',
        headerName: 'Cost Distribution Description',
        type: 'string',
        width: 220,
        editable: true,
    },
];

const CostCodeColumns: GridColDef[][] = [
    SegmentOneColumns,
    SegmentTwoColumns,
    CostTypeColumns,
    CostDistributionColumns,
];

export default CostCodeColumns;