import { useAppDispatch } from "../../../../app/hooks";
import CustomButton from "../../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
import { useEffect } from "react";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";

const RequestOrder = (props:any) => {
    const {order, orderType, handleRefresh} = props;
    const dispatch = useAppDispatch();
    const [updateData] = useUpdateDataMutation();

    const handleStatusUpdate = async () => {
        const requestData = {
            ...order,
            order_status: "REQUESTED"
        };
        const response = await updateData({
            tagName: "/warehouse-orders",
            url: `/warehouse/orders?id=${order?._id}`,
            body: requestData,
        }).unwrap();
        dispatch(showSnackbar({ message: response?.message || 'Data update is successful', type: 'success' }));
    }
    return (
        <CustomButton size="small" color="success" variant="contained" sx={{color: "white"}} onClick={handleStatusUpdate}>Request Order</CustomButton>
    )
}

export default RequestOrder; 