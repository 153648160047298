import { useEffect, useState } from 'react';

// Define inactivity timeout in milliseconds (e.g., 5 minutes)
const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes

const useInactivityTimer = (onTimeout:any) => {
    const [lastActivity, setLastActivity] = useState(Date.now());

    useEffect(() => {
        const resetTimer = () => {
            setLastActivity(Date.now());
        };

        // Listen to user interactions
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Inactivity checker
        const checkInactivity = () => {
            if (Date.now() - lastActivity >= INACTIVITY_TIMEOUT) {
                onTimeout();  // Trigger timeout action (e.g., logout)
            }
        };

        // Set an interval to check inactivity
        const intervalId = setInterval(checkInactivity, 1000); // Check every second

        return () => {
            // Cleanup event listeners and interval when component unmounts
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
            clearInterval(intervalId);
        };
    }, [lastActivity, onTimeout]);

    return lastActivity;
};

export default useInactivityTimer;