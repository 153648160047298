import { GridColDef } from "@mui/x-data-grid-premium";
import { useNavigate, useParams } from "react-router-dom";

const sovColumns:GridColDef[] = [
    {
      field: 'job_type_description',
      headerName: 'Job Type Description',
      type: 'string',
      width: 220,
      editable: true,
    },
    {
      field: 'construction_type',
      headerName: 'Construction Type',
      type: 'string',
      width: 220,
      editable: true,
    },
];

const sovItemColumns:GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 120,
    editable: true,
  },
];

const useSovItemColumns = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return [
    {
      field: 'plan',
      headerName: 'Plan',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'plan_description',
      headerName: 'Plan Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'elevation',
      headerName: 'Elevation',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'elevation_description',
      headerName: 'Elevation Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'option',
      headerName: 'Option',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'option_description',
      headerName: 'Option Description',
      type: 'string',
      width: 160,
      editable: true,
    },
    {
      field: 'option_code',
      headerName: 'Option Code',
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'number_of_budgets',
      headerName: 'No. Budgets',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'budgets',
      headerName: 'Budgets Rev. #',
      type: 'string',
      width: 160,
      editable: false,
      renderCell: (params: any) => {
        const budgets = params.value || []; // Ensure it's an array or fallback to an empty array
        return (
          <div>
            {budgets.map((budget: any, index: number) => (
              <div key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(`/projects/${project_id}/budgets/${budget?._id}`);
                  }}
                >
                  {`${budget?.budget_name} - ${budget?.budget_rev_no}` || 'N/A'}
                </a>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'number_of_proposals',
      headerName: 'No. Proposals',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'proposals',
      headerName: 'Proposals Rev #',
      type: 'string',
      width: 160,
      editable: false,
      renderCell: (params: any) => {
        const proposals = params.value || []; // Ensure it's an array or fallback to an empty array
        return (
          <div>
            {proposals.map((proposal: any, index: number) => (
              <div key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(`/projects/${project_id}/proposals/${proposal?._id}`);
                  }}
                >
                  {`${proposal?.proposal_name} - ${proposal?.proposal_rev_no}` || 'N/A'}
                </a>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'number_of_contracts',
      headerName: 'No. Contracts',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'contracts',
      headerName: 'Contracts Rev #',
      type: 'string',
      width: 160,
      editable: false,
      renderCell: (params: any) => {
        const contracts = params.value || []; // Ensure it's an array or fallback to an empty array
        return (
          <div>
            {contracts.map((contract: any, index: number) => (
              <div key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(`/projects/${project_id}/contracts/${contract?._id}`);
                  }}
                >
                  {`${contract?.contract_name} - ${contract?.contract_number}` || 'N/A'}
                </a>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'has_prebuilt_order',
      headerName: 'Pre-Built Order',
      type: 'boolean',
      width: 120,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 120,
      editable: true,
    },
  ];
};



export default {sovColumns, sovItemColumns, useSovItemColumns};
  