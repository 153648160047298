import { GridRenderCellParams } from "@mui/x-data-grid-premium";

const isValidUrl = (input:string) => {
    try {
        new URL(input);
        return true;
    } catch {
        return false;
    }
}

export const projectInformationColumns = [
    {
        field: 'item',
        headerName: 'Item',
        type: 'string',
        sortable: true,
        width: 220,
        editable: true,
        autocompletion: false,
        filterable: true
    },
    {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        sortable: true,
        width: 220,
        editable: true,
        autocompletion: false,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
            const value = params.value;
            if(isValidUrl(value)){
              return (
                  <a href={value} target="_blank" rel="noopener noreferrer">
                  {value}
                  </a>
              );
            }
            return value;
        },
    } 
  ];
  
  export default projectInformationColumns;