import cemexAPI from './cemexAPI';
import appAPI from './appAPI';

const getTargetInstance = (service:string) => {
    switch(service){
        case "cemex":
            return cemexAPI;
        case "app":
            return appAPI;
        default:
            return appAPI;
    }
}

// Get user projects
const getDoc = async (req:any) => {

    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.get(url);
    return response.data;
}

const createDoc = async (req:any) =>{
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.post(url, req.data);
    return response.data;
}

const uploadDoc = async (req:any) =>{
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const headers = {
        'Content-Type': 'multipart/form-data'
    };

    const response = await instance.post(url, req.data, {headers});
    return response.data;
}

const updateDoc = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(url, req.data);
    return response.data;
}

const deleteDoc = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);

    let response;
    if (req?.ids && req.ids.length > 0) {
        response = await instance.delete(url, {
            data: {
                ids: req.ids,
            },
        });
    } else {
        response = await instance.delete(url);
    }

    return response.data;
};


const deleteMany = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.delete(url, req?.data);
    return response.data;
}

const apiService = {
    getDoc,
    uploadDoc,
    updateDoc,
    deleteDoc,
    deleteMany,
    createDoc
}

export default apiService