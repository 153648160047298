import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import { useLocation } from "react-router-dom";
import ProjectDetailCols from "./ProjectDetailCols";
import LotCols  from "../../components/columns/lotColumns";
import styles from "./detail.module.css";
import DocumentView from "../../components/document-viewer/DocumentView";

function ItemPanelContent(props:any) {
    const {items, currentTabIndex, row} = props;
    const [localColumns, setLocalColumns] = useState<any>([]);
    const [rows, setRows] = useState<any>([]);
    const LotItemColumns = LotCols?.useLotItemColumns();

    const indexesWithDocuments = [
      9
    ]

    const generateUniqueColumns = (excludeFields:any, ...columnArrays:any[]) => {
      const uniques = new Set();
      const concatenated:any = [];

      for(const columnArray of columnArrays){
        for(const column of columnArray){
          if(!uniques.has(column.field) && !excludeFields.includes(column.field)){
            uniques.add(column.field);
            concatenated.push(column);
          }
        }
      }
      return concatenated;
    }

    useEffect(() => {
      switch (currentTabIndex) {
        case 4: 
          setLocalColumns(ProjectDetailCols?.BudgetItemSummaryColumns);
          break;
        case 5:
          setLocalColumns(ProjectDetailCols?.ProposalItemSummaryColumns);
          break;
        case 6:
          setLocalColumns(ProjectDetailCols?.ContractItemSummaryColumns);
          break;
        case 9:
          const excluded = ["lot_number", "address"];
          setLocalColumns(generateUniqueColumns(excluded, LotItemColumns));
          break;
        default:
          setLocalColumns([]);
          break;
      }
    }, [currentTabIndex, items]);

    useEffect(() => {
      if(items) setRows(items);
    },[items]);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2} className={styles.detailPanel} >
      {indexesWithDocuments?.includes(currentTabIndex) &&
       <Grid item sm={12} xs={12} >
        <DocumentView _id={row?._id} />
      </Grid>}
      <Grid item sm={12} xs={12} >
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)"
          }}
          initialState={{
            rowGrouping: {
              model: ['plan',],
            },
            sorting: {
              sortModel: [{field: "elevation", sort: 'asc'}]
            }
          }}
          isGroupExpandedByDefault={
            node => node.groupingField === 'plan'
          }
          columns={localColumns}
          rows={rows}
          editMode="row"
          getRowId={row => row?._id || row?.sov_id}
          density="compact"
          rowHeight={60}
          rowBuffer={10}
        /> 
      </Grid>
    </Grid>
  );
}

export default ItemPanelContent;


  