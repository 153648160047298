import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGridPremium,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from './projects.module.css';
import { config } from './config'; // Import the configuration}
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTheme } from "@mui/material";
import { useGetDataQuery } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import CustomGridToolbar from "../data-grid/toolbars/CustomGridToolbar";

const Overview = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { company, division, isAuthenticated } = useAppSelector((state: any) => state.auth);
  const theme = useTheme();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [context, setContext] = useState<any>(null);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  useEffect(() => {
    const currentContext:any = config?.[location.pathname];
    setContext(currentContext);
  }, [location.pathname]);

  const queryParams = useMemo(() => {
    if (!context) return null;
    return {
        tagName: context?.route,
        url: context.getUrl,
        params: { 
            company_id: company?._id, 
            division_id: division?._id
        }
    };
  }, [company, division, context]);

  const { data: response, status, isError, isLoading, error, isSuccess, refetch } = useGetDataQuery(queryParams, {
      skip: !context || !isAuthenticated,
  });

    // Optional: Trigger refetch manually if needed
    useEffect(() => {
        if (isSuccess && queryParams) {
            refetch(); // This will manually refetch the data if required
        }
    }, [company, division, queryParams, refetch]);

  useEffect(() => {
    if(isError && error)
        dispatch(showSnackbar({ 
            message: `Error Status ${status}: ${JSON.stringify(error?.toString()) || 'Failed to request order'}`, 
            type: 'error' 
        }));
    else if(isSuccess && response){
        dispatch(showSnackbar({ 
            message: `${response?.message || 'Operation successful'}`, 
            type: 'success' 
        }));
    }
  },[isError, error, response]);


  const handleRowDoubleClick = (params: any, event: any) => {
    event.preventDefault();
    const currentRow = response?.data?.find((row: any) => row._id === params.id);
    if (!currentRow) {
      console.log("No row found");
      return;
    }

    let navLink = "";
    switch(context?.route){
      case "/starts":
        navLink = context?.redirectUrl(currentRow?.project_id, currentRow?._id);
        break;
      case "/warehouse-orders":
        navLink = context?.redirectUrl(currentRow?.project_id, currentRow?.start_id);
        break;
      case "/projects":
        navLink = context?.redirectUrl(currentRow?._id);
        break;
      default: 
        break;
    }
    navigate(navLink);
    
  };

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  };

  return (
    <Box className={styles.gridContainer}>
      <Box className={styles.inner}>
        <DataGridPremium
          sx={{
            fontSize: 14,
            fontWeight: 500,
            textTransform: "uppercase",
            border: "1px solid rgba(255,255,255,0.5)",
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.primary.main,
              opacity: "0.8"
            },
          }}
          checkboxSelection
          editMode="row"
          getRowId={row => row._id}
          density="compact"
          rows={response?.data || []}
          initialState={{
            sorting: {
              sortModel: [{ field: 'start_date', sort: 'desc' }]
            },
            // columns: {
            //   columnVisibilityModel: {
            //     // Hide columns status and traderName, the other columns will remain visible
            //     company_id: false,
            //     division_id: false,
            //     customer_id: false,
            //     job_types: false
            //   },
            // },
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          columns={context?.columns || []}
          loading={isLoading}
          rowHeight={60}
          rowBuffer={10}
          onRowDoubleClick={handleRowDoubleClick}
          slots={{ toolbar: CustomGridToolbar }}
          slotProps={{ toolbar: { 
            context,
            columns: context?.columns,
            exportItems: response?.data?.filter((row: any) => row._id === rowSelectionModel[0]) } 
          }}
        />
      </Box>
      <Outlet />
    </Box>
  );
};

export default Overview;
