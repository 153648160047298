import { useMemo } from 'react';
import { apiRouteMap } from '../../routes/urls';
import { useGetDataQuery } from '../../api/apiSliceV2';

export const mapSovItemsToOptions = (data:any) => {
  const s = new Set();
  data?.forEach((row:any) => {
    s.add(`${row?.plan} - ${row?.elevation} - ${row?.option} - ${row?.item_id}`);
  });
  return Array.from(s);
};

const useSovOptions = (project_id:string, job_type_id:string) => {

  const params = useMemo(() => ({
    tagName: "/sov",
    url: apiRouteMap.get("/sov")?.getItems(project_id, job_type_id)
  }),[project_id, job_type_id])
  const {data: response, isLoading, error} = useGetDataQuery(params);


  return { response, isLoading, error };
};

export default useSovOptions; 