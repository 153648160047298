import projectColumns from "../columns/ProjectColumns";
import { SubjobColumns } from "../columns/SubjobColumns";
import WarehouseOrderCols from "../columns/WarehouseOrderCols";
import Steps from "../stepper/Steps";

export const config:any = {
  "/starts": {
    route: '/starts',
    id: "start_id",
    index: 0,
    getUrl: "/starts",
    redirectUrl: (...args:any) => {
      const [project_id, start_id] = args;
      return `/projects/${project_id}/starts/${start_id}`
    },
    ref: 'data',
    grouped: false,
    steps: Steps?.requestStart,
    columns: SubjobColumns
  },
  "/warehouse-orders": {
    route: '/warehouse-orders',
    id: "start_id",
    index: 1,
    getUrl: "/warehouse/orders",
    redirectUrl: (...args:any) => {
      const [project_id, start_id] = args;
      return `/projects/${project_id}/warehouse-orders/${start_id}`
    },
    ref: 'data',
    grouped: false,
    steps: [],
    columns: WarehouseOrderCols?.WarehouseOrderColumns
  },
  "/projects": {
    route: '/projects',
    id: "project_id",
    index: 1,
    getUrl: "/projects",
    redirectUrl: (...args:any) => {
      const [project_id] = args;
      return `/projects/${project_id}`
    },
    ref: 'data',
    grouped: false,
    steps: Steps?.projectCreation,
    columns: projectColumns
  },
};
