import React from "react";
import  DetailView  from "../components/detail-view/DetailView";
import useWarehouseConfig from "./useWarehouseConfig";

const WarehouseOrderDetail = () => {
  const {tabs}: any = useWarehouseConfig();
  return <DetailView tabs={tabs} />
}

export default WarehouseOrderDetail;

