import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks";
import {  useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AutoCompleteOption, formData } from "../../../utils/formDefs";
import { CircularProgress } from '@mui/material';
import { setSelectedItem } from "../../components/stepper/stepperSlice";
import CustomButton from "../../components/button/CustomButton";
import { useGetAllJobTypesQuery, useGetCompanyNumbersQuery, useGetDivisionNumbersQuery, useGetProjectsQuery, useGetSupervisorsQuery } from "../../dropdowns/dropdownApi";
import { useCreateDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { apiRouteMap } from "../../routes/urls";

const RequestStart = (props:any) => {
    const {setCompleted, completed, activeStep} = props;
    const dispatch = useAppDispatch();
    const {project_id} = useParams();
    const { selectedItem } = useAppSelector((state:any) => state.stepper);
    const {company, division} = useAppSelector((state:any) => state.auth);
    const [formData, setFormData] = useState<formData>({});
    const [formErrors, setFormErrors] = useState<any>({});
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());

    const clearFormErrors = () => {
        setFormErrors({});
    }

    const [autocompleteOptions, setAutocompleteOptions] = useState<AutoCompleteOption>({
        projects: [],
        job_type: [],
        supervisor: [],
        companies: [],
        divisions: [],
    });

    const projectParams = useMemo(() => ({
        company_id: company?._id,
        division_id: division?._id,
    }),[company, division]);
    const {data: projects} = useGetProjectsQuery(projectParams);
    const employeeParams = useMemo(() => {
        const selectedProject = projects?.data?.find((project:any) => project.job_number === formData["job_number"])
        return {
            project_id: project_id || selectedProject?._id || "",
            division_id: division?._id || ""
        }
    },[division, projects, formData]);
    const {data: supervisors} = useGetSupervisorsQuery(employeeParams);
    
    const jobTypeParams = useMemo(() => ({project_id: project_id || "", division_id: division?._id || ""}),[])
    const { data: job_types } = useGetAllJobTypesQuery(jobTypeParams);
    const { data: company_numbers } = useGetCompanyNumbersQuery();
    const { data: division_numbers } = useGetDivisionNumbersQuery({company_id: company?._id});
    const [createData, {isLoading}] = useCreateDataMutation();

    useEffect(() => {
        
        if(projects && supervisors && job_types){
            setAutocompleteOptions({
                job_number: projects?.data.map((project:any) => project?.job_number),
                job_type: job_types?.data.map((job_type:any) => job_type?.job_type_description),
                supervisor: supervisors?.data?.map((supervisor:any) => supervisor?.employee_name),
                companies: company_numbers,
                divisions: division_numbers
            });
        }
    }, [projects, supervisors, job_types, company_numbers, division_numbers]);

    const handleSave = async () => {
        try { 
            const project = projects?.data?.find((project:any) => {
                if(formData.job_number === project.job_number || project_id === project?._id) 
                    return project;
            });                

            const job_type = job_types?.data?.find((jt:any) => {
                return jt.job_type_description === formData?.["job_type"]
            }); 

            const supervisor = supervisors?.data?.find((s:any) => s?.employee_name === formData?.supervisor);
            let newStart = {
                ...formData,
                job_number: formData?.job_number || project?.job_number,
                project_id: project?._id || project_id,
                job_type_id: job_type?._id,
                supervisor: supervisor?._id,
                start_date: selectedDate, 
            };
            // Request start
            const res = await createData({
                body: newStart,
                url: apiRouteMap.get("/starts").create(project?._id)
            }).unwrap();
            dispatch(setSelectedItem(res?.start))
            dispatch(showSnackbar({ message: res?.message || 'Operation successful', type: 'success' }));
            setCompleted({[0]: true});
            clearFormErrors();
            
        } catch (error:any) {
            const errorMessage = error?.data?.message || 'Failed to create data';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        }
    };
    
    const handleAutocompleteChange = (name: string, newValue: string | null) => {
        setFormData(prevState => ({ ...prevState, [name]: newValue || "" }));  // Default to empty string if null
    };

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    return (

        <Grid container justifyContent="center" alignItems="center" columns={12} sx={{width: "auto", p: 2, margin: "0 auto"}} spacing={1}>
            {completed[activeStep] ? (
                <Grid sx={{textAlign: "center"}} item md={6} sm={8} xs={12}>
                    <Box sx={{width: "100%", display: "flex", justifyContent: "center" }}>
                        <Box sx={{textAlign: "start"}}>
                            <Typography variant="body1">Company Number: {company?.company_number}</Typography>
                            <Typography variant="body1">Division Number: {division?.division_number}</Typography>
                            <Typography variant="body1">Job Number: {selectedItem?.job_number}</Typography>
                            <Typography variant="body1">Subjob Number: {selectedItem?.subjob_number}</Typography>
                            <Typography variant="body1">Job Type: {selectedItem?.job_type}</Typography>
                            <Typography variant="body1">Supervisor: {selectedItem?.supervisor}</Typography>
                            <Typography variant="body1">Notes: {selectedItem?.notes}</Typography>
                        </Box>
                    </Box>

                    
                </Grid>
            ):(
            <>
                <Grid item sm={12}  xs={12}>
                    <Typography variant="body2" sx={{p: 2, textAlign: "center", fontSize: "1.2rem"}}>Request Start</Typography>
                </Grid>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{mt: 1.5, mb: 2}}
                                formatDensity="dense"
                                label="Date"
                                value={selectedDate}
                                onChange={(newValue) => setSelectedDate(newValue)}
                            />
                        </LocalizationProvider>               
                    </Grid> 
                </Grid>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1} >
                    {!project_id && 
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            options={autocompleteOptions?.job_number || []}
                            getOptionLabel={(option) => option }
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    size="small"
                                    margin="dense"
                                    label="Job #"
                                    type="string"
                                    fullWidth
                                    name="job_number"
                                    error={!!formErrors?.job_number}
                                />
                            )}
                            value={formData.job_number}
                            onChange={(event, newValue) => handleAutocompleteChange("job_number", newValue)}
                        />
                </Grid>}
                <Grid item xs={12} sm={3} >
                    <TextField
                        variant="standard"  
                        size="small"
                        margin="dense"
                        label="Subjob #"
                        type="string"
                        fullWidth
                        name="subjob_number"
                        value={formData.subjob_number}
                        onChange={handleChange}
                        error={!!formErrors?.subjob_number}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Autocomplete
                        options={autocompleteOptions.job_type}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                {...params}
                                size="small"
                                margin="dense"
                                label="Job Type"
                                fullWidth
                                error={!!formErrors?.job_type}
                            />
                        )}
                        value={formData.job_type}
                        onChange={(event, newValue) => handleAutocompleteChange("job_type", newValue)}
                    />
                </Grid>
                </Grid> 
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={12} >
                    <Autocomplete
                        options={autocompleteOptions.supervisor}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                variant="standard"  
                                {...params}
                                size="small"
                                margin="dense"
                                label="Supervisor"
                                fullWidth
                                error={!!formErrors?.supervisor}
                            />
                        )}
                        value={formData.supervisor}
                        onChange={(event, newValue) => handleAutocompleteChange("supervisor", newValue)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} >
                    <TextField
                        variant="standard"
                        multiline
                        rows={1}
                        size="small"
                        margin="dense"
                        label="Notes"
                        type="string"
                        fullWidth
                        name="notes"
                        value={formData.notes}
                        onChange={handleChange}
                    />
                </Grid>
                </Grid>

                <Grid item sm={12} xs={12} sx={{mt: 6}}>
                    <Box sx={{float: "right"}}>
                        <CustomButton variant="contained" size="small" color="primary" onClick={handleSave}>
                            {isLoading ? <CircularProgress size={24} /> : "Submit"}
                        </CustomButton>
                    </Box>              
                </Grid>
            </>
            )}
        </Grid>
    );

}

export default RequestStart;


