import React, { useState } from "react"
import { useAppSelector } from "../../app/hooks";
import Box from "@mui/material/Box";
import {
  DataGridPremium, 
} from "@mui/x-data-grid-premium";
import styles from "./report.module.css";
import { Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { JCReportColumns } from "../components/columns/JCReportColumns";
import contractItemsReportColumns from "../components/columns/contractItemsReportColumns";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useGetContractItemsQuery, useGetJobCostReportQuery, useGetOrdProcEntQuery } from "../dropdowns/dropdownApi";
import ordProcEntColumns from "../components/columns/ordProcEntColumns";
import ExportToolbar from "../components/data-grid/toolbars/ExportToolbar";
import StartHeader from "../starts/StartIHeader";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style: any;
}

export function TabPanel(props: TabPanelProps) {
  const { style, children, value, index, ...other } = props;

  return (
    <Box sx={style} role="tabpanel" hidden={value !== index} id={`dash-tabpanel-${index}`} aria-labelledby={`dash-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export  function tabProps(index: number) {
  return {
    id: `dash-tab-${index}`,
    'aria-controls': `dash-tabpanel-${index}`,
  };
}



const ReportView = () => {
  const {selectedItem} = useAppSelector((state:any) => state.api);
  const {projects} = useAppSelector((state:any) => state.dropdown);
  const {start_id, project_id} = useParams();
  const [value, setValue] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);

  const {data: jobCostItems, isLoading, error} = useGetJobCostReportQuery({start_id: start_id || ""});
  const {data: contractItems} = useGetContractItemsQuery({start_id: start_id || ""});
  const {data: ordProcEntItems} = useGetOrdProcEntQuery({start_id: start_id || ""});

  const tabs = [
    {index: 0, label: "Job Cost Report", data: jobCostItems?.data, columns: JCReportColumns},
    {index: 1, label: "Contract Items", data: contractItems?.data, columns: contractItemsReportColumns},
    {index: 2, label: "OrdProcEnt Items", data: ordProcEntItems?.data, columns: ordProcEntColumns}
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const toggleHeaderVisibility = () => {
    setIsHeaderVisible((current:boolean) => !current);
  }
   
  return (  
    <>
      <Grid container className={styles.gridContainer}>
      {isHeaderVisible &&
        <StartHeader
          selectedItem={selectedItem || {}}
          editable={true}
        />}

        <Grid item xs={12} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
          <IconButton size="large" color="primary" onClick={toggleHeaderVisibility}>
            {isHeaderVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>

        <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-start", borderBottom: 1, borderColor: 'divider', width: "100%", height: "auto", padding: "1rem"}}>
            <Tabs allowScrollButtonsMobile scrollButtons={true}  value={value} onChange={handleTabChange} aria-label="basic tabs example" selectionFollowsFocus>
              <Tab label="Job Cost Report" {...tabProps(0)} />
              <Tab label="Contract Items" {...tabProps(1)} />
              <Tab label="OrdProcEnt Items" {...tabProps(2)} />                               
            </Tabs>
        </Grid>
        <Grid sx={{width: "100%", height: "auto", maxHeight: "800px"}}>
          {tabs?.map((tab:any) => {
            return (
              <TabPanel style={{height: "auto", width: "100%", p: 2}} value={value} index={tab?.index}>
                <Grid item sx={{width: "100%", height: "auto", maxHeight: "800px"}}>
                  <DataGridPremium
                    sx={{fontWeight: 500, height: "800px"}}
                    density="compact"
                    editMode="row"
                    getRowId={row => row?.id || row?._id}
                    rows={tab?.data || []}
                    columns={tab?.columns || []}
                    loading={isLoading}
                    rowHeight={60}
                    rowBuffer={10}
                    slots={{ toolbar: ExportToolbar }}
                    slotProps={{toolbar: {exportItems: [{job_number: projects?.find((project:any) => project?._id === project_id)?.job_number, ...selectedItem}], tabIndex: value}}}
                  />
                </Grid>  
              </TabPanel>
            )
          })}
        </Grid>
      </Grid>
    </>   
  );
}

export default ReportView
