import { formatDateStringWithTime } from "../../../utils/formatDateString";

const BudgetItemSummaryColumns = [
    {
        field: 'plan',
        headerName: 'Plan',
        width: 120,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'elevation',
        headerName: 'Elevation',
        width: 120,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option',
        headerName: 'Option',
        width: 120,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option_code',
        headerName: 'Option Code',
        type: 'string',
        width: 160,
        editable: true,
    },
    {
        field: 'total_cost',
        headerName: 'Total Cost',
        width: 120,
        type: 'number',
        sortable: true,
        editable: false,
        valueGetter: (params:any):string => {
            if(params.value){
                return `$${params.value?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            }else{
                return '';
            }
        },
    }
];

const ProposalItemSummaryColumns = [
    {
        field: 'plan',
        headerName: 'Plan',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'elevation',
        headerName: 'Elevation',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option',
        headerName: 'Option',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option_code',
        headerName: 'Option Code',
        type: 'string',
        width: 160,
        editable: true,
    },
    {
        field: 'total_price',
        headerName: 'Total Price',
        width: 150,
        type: 'number',
        sortable: true,
        editable: false,
        valueGetter: (params:any):string => {
            if(params.value){
                return `$${params.value?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            }else{
                return '';
            }
        },
    }
];

const ContractItemSummaryColumns = [
    {
        field: 'plan',
        headerName: 'Plan',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'elevation',
        headerName: 'Elevation',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option',
        headerName: 'Option',
        width: 160,
        type: 'string',
        sortable: true,
        editable: false
    },
    {
        field: 'option_code',
        headerName: 'Option Code',
        type: 'string',
        width: 160,
        editable: true,
    },
    {
        field: 'total_price',
        headerName: 'Total Price',
        width: 150,
        type: 'number',
        sortable: true,
        editable: false,
        valueGetter: (params:any):string => {
            if(params.value){
                return `$${params.value?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            }else{
                return '';
            }
        },
    }
];

export default {
    BudgetItemSummaryColumns,
    ProposalItemSummaryColumns,
    ContractItemSummaryColumns
};