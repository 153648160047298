import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { useUploadDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';

const ImportComponent = ({handleClose, step, completed, activeStep, setCompleted, job_type }:any) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const {selectedItem} = useAppSelector((state:any) => state.stepper);
    const [selectedJobType, setSelectedJobType] = useState<any>({});
    const {project_id, budget_id, proposal_id, contract_id} = useParams();
    const [uploadData, {data: response, isLoading}] = useUploadDataMutation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedJobType(job_type);
    },[job_type]);
    
    const handleFileChange = (event:any) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleImport = async () => {
        try {
            if (selectedFile && selectedItem) {    
                const formData = new FormData();
                formData.append('file', selectedFile);
                const id = step?.idType === "project_id" ? project_id : (budget_id || proposal_id || contract_id || selectedItem?._id);

                const response = await uploadData({
                    tagName: step?.route,
                    url: step?.importUrl(id, selectedJobType),
                    body: formData,  
                }).unwrap();

                dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
                setCompleted({[activeStep]: true});
            }
        } catch (error:any) {
            setSelectedFile(null);
            dispatch(showSnackbar({ 
                message: `Error: ${JSON.stringify(error?.data) || 'Failed to create data'}`, 
                type: 'error' 
            }));
        }   
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sx={{height: "400px"}}>
                <DataGridPremium
                    sx={{
                        fontSize: 14, 
                        fontWeight: 500, 
                        border: "1px solid rgba(0,0,0,0.25)"
                    }}
                    editMode="row"
                    getRowId={row => row.id || row._id}
                    density="compact"
                    rows={response?.data || []}
                    columns={step?.column || []}
                    loading={isLoading}
                    rowHeight={60}
                    rowBuffer={10}
                />
            </Grid>           
            <Grid item xs={12}>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button sx={{fontWeight: 600}} variant="contained" component="label">

                        Select File
                        <input type="file" hidden onChange={handleFileChange} />
                    </Button>
            
                    {!completed[activeStep] ? (
                        <Button sx={{marginLeft: 1, fontWeight: 600}} variant="contained" onClick={handleImport} disabled={!selectedFile}>
                            Import
                        </Button>
                    ):(
                        <Button sx={{marginLeft: 1, fontWeight: 600}} variant="contained" onClick={() => handleClose((close:boolean) => !close)}>Close</Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ImportComponent;
