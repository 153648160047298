import React from 'react';
import GridView from '../components/data-grid/GridView';
import Dashboard from '../dashboard/Dashboard';
import ReportView from '../reports/ReportView';
import WarehouseOrderDetail from '../warehouse/WarehouseOrderDetail';
import ProjectDetail from '../projects/project-detail/ProjectDetail';
import SelectGrid from '../projects/project-items/SelectGrid';
import StartDetail from '../starts/StartDetail';
import Overview from '../components/overview/Overview';
import OrgUnitDetail from '../organizational-units/OrgUnitDetail';
import { URLS } from './urls';
 
interface RouteType {
    id: string;
    name: string;
    path: string;
    component: React.ComponentType<any>;
    childRoutes?: RouteType[];
}

const routes: {[key: string]: RouteType} = {
    root: {
        id: "root",
        name: "Root",
        path: URLS.root,
        component: (routeProps) => <Dashboard {...routeProps} /> ,
    },
    projects: {
        id: "projects",
        name: "Projects",
        path: URLS.projects,
        component: (routeProps) => <Overview {...routeProps} /> ,    
    },
    projectDetail: {
        id: "project_detail",
        name: "Project Detail",
        path: URLS.projectDetail,
        component: (routeProps) => <ProjectDetail {...routeProps} /> ,    
    },
    proposal: {
        id: "proposalItems",
        name: "Proposal Items",
        path: URLS.proposal,
        component: (routeProps) => <SelectGrid {...routeProps} /> ,
    },
    budget: {
        id: "budgetItems",
        name: "Budget Items",
        path: URLS.budget,
        component: (routeProps) => <SelectGrid {...routeProps} /> ,

    },
    scheduleOfValues: {
        id: "scheduleOfValues",
        name: "Schedule of Values",
        path: URLS.scheduleOfValues,
        component: (routeProps) => <SelectGrid {...routeProps} /> ,
    },
    contract: {
        id: "contractItems",
        name: "Contract Items",
        path: URLS.contract,
        component: (routeProps) => <SelectGrid {...routeProps} /> ,
    },
    whMaterialPricing: {
        id: "whMaterialPricing",
        name: "Warehouse Material Pricing",
        path: URLS.warehouseMaterialPricing,
        component: (routeProps) => <GridView {...routeProps} /> ,

    },
    concretePricing: {
        id: "concretePricing",
        name: "Concrete Pricing",
        path: URLS.concreteMixDesignPricing,
        component: (routeProps) => <GridView {...routeProps} /> ,
    },
    aggregatePricing: {
        id: "aggregateQuoteList",
        name: "Aggregate Pricing List",
        path: URLS.aggregatePricing,
        component: (routeProps) => <GridView {...routeProps} /> ,
    },
    warehouseOrders: {
        id: "warehouseOrders",
        name: "Warehouse Orders",
        path: URLS.warehouseOrders,
        component: (routeProps) => <Overview {...routeProps} /> ,

    },
    warehouseOrderDetail: {
        id: "warehouseOrderDetail",
        name: "Warehouse Order Detail",
        path: URLS.warehouseOrderDetail,
        component: (routeProps) => <WarehouseOrderDetail {...routeProps} />
    },
    starts: {
        id: "start",
        name: "Start",
        path: URLS.starts,
        component: (routeProps) => <Overview {...routeProps} /> ,
    },
    startDetail: {
        id: "startDetail",
        name: "Start Detail",
        path: URLS.startDetail,
        component: (routeProps) => <StartDetail {...routeProps} />
    },
    organizationalUnitDetail: {
        id: "organizationalUnitDetail",
        name: "Organizational Units",
        path: URLS.organizationalUnits,
        component: (routeProps) => <OrgUnitDetail {...routeProps} />
    },
    reports: {
        id: "reports",
        name: "Reports",
        path: URLS.reports,
        component: (routeProps) => <ReportView {...routeProps} /> ,
    },
}

export const routeList = [...Object.values(routes)];