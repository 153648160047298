const contractItemReportColumns = [
  { field: 'Lot Number', headerName: 'Lot Number', width: 120 },
  { field: 'Address', headerName: 'Address', width: 200 },
  { field: 'Garage Orientation', headerName: 'Garage Orientation', width: 180 },
  { field: 'Plan Name', headerName: 'Plan Name', width: 120 },
  { field: 'Elevation', headerName: 'Elevation', width: 120 },
  { field: 'Option Name', headerName: 'Option Name', width: 150 },
  { field: 'Contract Revision', headerName: 'Contract Revision', width: 160 },
  { field: 'Contract Quantity', headerName: 'Contract Quantity', width: 150, type: 'number' },
  { field: 'Contract UoM', headerName: 'Contract UoM', width: 150 },
  { field: 'Contract $/Unit', headerName: 'Contract $/Unit', width: 150, type: 'number', 
    valueGetter: (params:any):string => {
      if(params.value){
          return `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
      }else{
          return '';
      }
    },
   },
  { field: 'Contract Amount', headerName: 'Contract Amount', width: 150, type: 'number', 
    valueGetter: (params:any):string => {
      if(params.value){
          return `$${params.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
      }else{
          return '';
      }
    },   
  },
  { field: 'Proposal Revision', headerName: 'Proposal Revision', width: 160 },
  { field: 'Proposal Quantity', headerName: 'Proposal Quantity', width: 150, type: 'number', },
  { field: 'Proposal UoM', headerName: 'Proposal UoM', width: 150 },
  { field: 'Proposal Amount', headerName: 'Proposal Amount', width: 150, type: 'number',
  valueGetter: (params:any):string => {
    if(params.value){
        return `$${params.value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })}`;
    }else{
        return '';
    }
  },
   },
  { field: 'Proposal $/Unit', headerName: 'Proposal $/Unit', width: 150, type: 'number',
  valueGetter: (params:any):string => {
    if(params.value){
        return `$${params.value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })}`;
    }else{
        return '';
    }
  },
   },
  { field: 'Contract Type Two Price', headerName: 'Contract Type Two Price', width: 200, type: 'number' },
  { field: 'SKU#', headerName: 'SKU#', width: 220 }
];

export default contractItemReportColumns;