import { Box } from '@mui/material';
import { GridToolbarContainer} from '@mui/x-data-grid';
import React from 'react';
import AddItems from '../../../starts/sov-search/AddItems';

const ActionToolbar = (props: any) => {

    return(
        <Box>
            <GridToolbarContainer>
                <AddItems {...props} />
            </GridToolbarContainer>
        </Box>
        
    )
}

export default ActionToolbar;
