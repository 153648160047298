import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Grid, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { reset, setSelectedItem } from "./stepperSlice";
import CustomButton from "../button/CustomButton";

const DynamicStepper = (props:any) => {
    const {context, columns} = props;
    const {selectedItem} = useAppSelector((state:any) => state.stepper);
    const [completed, setCompleted] = useState<{[k: number]: boolean}>({});
    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const stepLength = context?.steps?.length;
    const navigate = useNavigate();

    useEffect(() => {
        if(completed[activeStep] && context?.steps?.[activeStep]?.redirect){
            const redirectUrl = (selectedItem?.project_id) ? 
                context?.steps?.[activeStep]?.redirect(selectedItem?.project_id, selectedItem?._id || "") 
            : 
                context?.steps?.[activeStep]?.redirect(selectedItem?._id || "");
            setTimeout(() => navigate(redirectUrl),1500);
        } 
    },[activeStep, completed, selectedItem]);

    const isStepOptional = (stepIndex:number) => {
        return context?.steps?.[stepIndex]?.isOptional;
    }

    const isLastStep = () => {
        return context?.steps?.length -1 === activeStep;
    }

    const handleStep = (step: number) => () => {
        if (step > stepLength - 1 || step < 0) return; 
    
        const currentStepCompleted = completed[activeStep];
        const currentStepOptional = isStepOptional(activeStep);
 
        if (step <= activeStep || currentStepOptional || currentStepCompleted) {
            setActiveStep(step); 
        } else if (isStepOptional(step) && Object.keys(completed).length >= step && completed[step - 1]) {
            setActiveStep(step);
        } else {
            console.log("Cannot navigate to an uncompleted, non-optional step ahead or skip current mandatory step.");
        }
    };

    const handleOpen = () => {
        setOpen(true);
    }
    
    const handleClose = async () => {
        setOpen(false);
        resetState();
        dispatch(setSelectedItem({}));    
    }

    const resetState = async () => {
        setActiveStep(0);
        setCompleted({});
        dispatch(reset());
    }

    useEffect(() => {
        if(completed[activeStep] && activeStep !== context?.steps.length -1 ){
            setActiveStep(activeStep + 1)
        }
    }, [completed]);

    return (
        <>
            <CustomButton variant="contained" size="small" color="primary" onClick={handleOpen}>
                <Typography sx={{fontWeight: 800, color: "white"}} variant="body2">
                    {` ${context?.steps?.[activeStep]?.label}`}
                </Typography>
            </CustomButton>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <Grid container justifyContent="center" alignItems="center" sx={{height: "auto"}}>
                        <Grid item lg={5.5} sm={7.5} xs={11.5} sx={{ order: 1}}>
                            <Stepper activeStep={activeStep}>
                                {context?.steps && context?.steps?.map((step:any, index:number) => {
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    if(isStepOptional(index)){
                                        labelProps.optional = (
                                            <Typography variant="caption">Optional</Typography>
                                        );
                                    }
                                    return (
                                        <Step key={step?.label} onClick={handleStep(index)} completed={completed[index]}>
                                            <StepLabel {...labelProps}>{step?.label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>
                        <Grid item sm={0.5} xs={12} sx={{display: "flex", justifyContent: "flex-end", order: 2, padding: 1}}>
                            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => handleClose()}
                                    size="small"
                                    sx={{opacity: 0.9}}
                                >
                                    <ClearSharpIcon fontSize="medium" />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{marginTop: "1em", justifyContent: "center", alignItems: "center" }}>
                        {context?.steps && context?.steps?.map((step:any, index:number) => {
                            if(activeStep === index){
                                return (
                                    <Grid item key={index} lg={6} sm={12} sx={{mt:2, p: 1}}>
                                        <step.Component
                                            step={step}
                                            columns={columns}
                                            open={open}
                                            isLastStep={isLastStep}
                                            handleClose={handleClose}
                                            completed={completed}
                                            activeStep={activeStep}
                                            setCompleted={setCompleted}  
                                        />
                                    </Grid>
                                );
                            }
                        })}                      
                    </Grid>                 
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DynamicStepper;
