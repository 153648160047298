import CemexOrderColumns from "./cemexOrderColumns";
import CostCodeColumns from "./costCodeColumns";
import specificationColumns from "./specificationColumns";
import LotCols from "./lotColumns";
import PlpColumns from "./plpColumns";
import SubjobDetailCols from "./SubjobDetailCols";
import { whMaterialPricingColumns } from "./whMaterialPricing";

import ProjectColumns from "./ProjectColumns";
import { BudgetColumns } from "./fndBudgetColumns";
import concretePricingColumns from "./concretePricingColumns";
import aggregatePricingColumns from "./aggregatePricingColumns";
import WarehouseOrderCols from "./WarehouseOrderCols";

let columnMap = new Map()
columnMap.set("budgets",{staticCols: BudgetColumns, dynamicCols: []});
columnMap.set("jobs",{staticCols: [], dynamicCols: []});
columnMap.set("lots",{staticCols: LotCols?.LotColumns, dynamicCols: []});
columnMap.set("cost-codes",{staticCols: [], dynamicCols: CostCodeColumns});
columnMap.set("fnd-specs",{staticCols: specificationColumns?.stepTwo, dynamicCols: []});
columnMap.set("/warehouse-materials",{staticCols: whMaterialPricingColumns, dynamicCols: []});
columnMap.set("/concrete/mix-design-pricings",{staticCols: concretePricingColumns, dynamicCols: []});
columnMap.set("/concrete/aggregate-pricing",{staticCols: aggregatePricingColumns, dynamicCols: []});

columnMap.set("/warehouse-orders",{staticCols: WarehouseOrderCols?.WarehouseOrderColumns, dynamicCols: []});
columnMap.set("plps",{staticCols: PlpColumns, dynamicCols: []});
columnMap.set("cemex-orders",{staticCols: CemexOrderColumns, dynamicCols: []});
columnMap.set('starts',{staticCols: SubjobDetailCols, dynamicCols: []});
columnMap.set('projects',{staticCols: ProjectColumns, dynamicCols: []});
columnMap.set('proposals',{staticCols: {}, dynamicCols: []});
columnMap.set('proposals/items',{
    detail: {},
    detailItems: [],
    staticCols: {}, 
    dynamicCols: []
});
columnMap.set('contracts',{staticCols:{}, dynamicCols: []});
columnMap.set('contracts/items',{});

export default columnMap;