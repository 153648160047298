import { useAppDispatch } from "../../../../app/hooks";
import CustomButton from "../../../components/button/CustomButton";
import { useEffect } from "react";
import { useUpdateDataMutation } from "../../../api/apiSliceV2";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";


const RejectOrder = (props:any) => {
    const {order, orderType} = props;
    const dispatch = useAppDispatch();
    const [updateData, {status, isError, error}] = useUpdateDataMutation();

    const handleStatusUpdate = async () => {
        try {
            const requestData = {
                ...order,
                order_status: "REJECTED"
            };
            const response = await updateData({
                tagName: "/warehouse-orders",
                url: `/warehouse/orders?id=${order?._id}`,
                body: requestData,
            }).unwrap();
            dispatch(showSnackbar({ message: response?.message || 'Data update is successful', type: 'success' }));

        } catch (error:any) {
            dispatch(showSnackbar({ message: error?.data?.message || error?.message, type: 'error' }));
        }
    }
    return (
        <CustomButton size="small" color="error" variant="contained" sx={{color: "white"}} onClick={handleStatusUpdate}>Reject Order</CustomButton>
    )
}

export default RejectOrder;