import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ObjectID from "bson-objectid";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCreateDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import { setFormErrors, setSelectedItem } from "../stepper/stepperSlice";

const FormComponent = (props:any) => {
    const {
        step,
        columns,
        setCompleted,
        activeStep,
        completed,
        handleClose,
        autocompleteOptions,
        onJobTypeChange,
        onCompanyChange
    } = props;
    const dispatch = useAppDispatch();
    const {isMobile} = useAppSelector((state:any) => state.layout);
    const {selectedItem, formErrors} = useAppSelector((state:any) => state.stepper);
    const {company, division} = useAppSelector((state:any) => state.auth);
    const {
        project_id,
        budget_id,
        proposal_id,
        contract_id} = useParams();
    const [formData, setFormData] = useState<any>({});
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());

    const [createData] = useCreateDataMutation();

    const excludedFields = [
        '_id',
        '_v',
        "__v",
        'job_id',
        'project_id',
        'budget_id',
        'proposal_id',
        'contract_id',
        'created_by',
        'created_at',
        'updated_by',
        'updated_at',
        "items",
        "budget_items",
        "order_items",
        "subjob_number",
        "builders",
        "schedule_of_values_id",
    ];

    const translationRequired = [
        "proposal_reference",
        "budget_reference",
        "company_id",
        "division_id",
        "customer_id",
        "builders",
        "customers",
        "job_types",
        "job_type",
        "job_type_id",
        "supervisor",
        "estimator",
        "foreman",
        "jc_accountant"
    ]

    useEffect(() => {
        const initialFormData: any = {};
        columns?.forEach((column: any) => {
            if (column.type === 'date') {
                initialFormData[column.field] = dayjs();
            } else {
                initialFormData[column.field] = '';
            }
        });
        setFormData(initialFormData);
    }, [columns, project_id]);  

    useEffect(() => {
        console.log(autocompleteOptions)
    },[autocompleteOptions]);

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData((prevState:any) => ({ ...prevState, [name]: value }));
    };

    const handleDateChange = (field: string, newValue: any) => {
        // Update formData state to reflect the new date value
        setFormData((prevState: any) => ({
            ...prevState,
            [field]: newValue,
        }));
    };
    const handleSave = async () => {
        try {
            let jsonData = translateIds({
                ...formData,
                _id: new ObjectID(),
                company_number: formData?.["company_number"] || company?.company_number,
                division_number: formData?.["division_number"] || division?.division_number,
                company_id: company?._id,
                division_id: division?._id, 
            });

            if(step?.idType === 'project_id'){
                jsonData = { project_id, ...jsonData };                
            }

            const id = (step?.idType === 'project_id') ? project_id  : (budget_id || proposal_id || contract_id || selectedItem?._id);
            const res = await createData({
                tagName: step?.route,
                url: step?.saveUrl(id),
                body: jsonData,
            }).unwrap();

            dispatch(showSnackbar({ message: res?.message || 'Operation successful', type: 'success' }));
            dispatch(setSelectedItem(res?.data))
            setCompleted({[activeStep]: true});
        } catch (err:any) {
            const {errors} = err?.data?.error;
            dispatch(setFormErrors(errors))
            const errorMessage = err?.data?.message || 'Failed to create data';
            dispatch(showSnackbar({ message: errorMessage, type: 'error' }));
        } 
    };

    const translateIds = (jsonData:any) => {
        try {
            for (const [key, val] of Object.entries(jsonData)) {
                if (translationRequired.includes(key)) {
                    console.log(key, val)
                    switch (key) {
                        case "proposal_reference":
                            const proposal = autocompleteOptions?.[key]?.data?.find((proposal:any) =>  `${proposal?.proposal_name} - ${proposal?.proposal_rev_no}` === val);
                            jsonData[key] = proposal?._id || val; 
                            break;
                        case "budget_reference":
                            const budget = autocompleteOptions?.[key]?.data?.find((budget:any) => {
                                return `${budget?.budget_name} - ${budget?.budget_rev_no}` === val
                            });
                            jsonData[key] = budget?._id || val; 
                            break;
                        case "company_id":
                            const co = autocompleteOptions?.[key]?.data?.find((co:any) => co?.company_number === val) || company;
                            jsonData[key] = co?._id || val; 
                            break;
                        case "division_id":
                            const div = autocompleteOptions?.[key]?.data?.data?.find((d:any) => d?.division_number === val) || division;
                            jsonData[key] = div?._id || val; 
                            break;
                        case "customer_id":
                            const customer = autocompleteOptions?.[key]?.data?.find((c:any) => c?.customer_description === val);
                            jsonData[key] = customer?._id || val; 
                            break;
                        case "supervisor":
                            const supervisor = autocompleteOptions?.[key]?.data?.find((s:any) => {
                                return s?.employee_name === val
                            });
                            jsonData[key] = supervisor?._id || undefined; 
                            break;
                        case "foreman":
                            const foreman = autocompleteOptions?.[key]?.data?.find((f:any) => f?.employee_name === val);
                            jsonData[key] = foreman?._id || undefined; 
                            break;
                        case "estimator":
                            const estimator = autocompleteOptions?.[key]?.data?.find((e:any) => e?.employee_name === val);
                            jsonData[key] = estimator?._id || undefined; 
                            break;
                        case "jc_accountant":
                            const jc_accountant = autocompleteOptions?.[key]?.data?.find((j:any) => j?.employee_name === val);
                            jsonData[key] = jc_accountant?._id || undefined; 
                            break;
                        case "job_type":
                                const translated = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === jsonData["job_type"]);
                                jsonData["job_type_id"] = translated?._id;  
                            break;
                        case "job_types":
                            if (Array.isArray(val)) {
                                const translatedJobTypes = val?.map((job_type) => {
                                    const found = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === job_type);
                                    return found?._id || job_type;   
                                });
                                jsonData[key] = translatedJobTypes;   
                            }   
                            break;
                        default:
                            break;
                    }
                }
            }
            return jsonData;
        } catch (error) {
            console.log(error);
        }
        return jsonData;
    };

    const getKey = (str:string) => {
        for(const key of Object.keys(autocompleteOptions)){
            if(str.includes(key)){
                return key;
            }
        }
        return "";
    }
    
    return (
    <Grid container justifyContent="center" alignItems="center" sx={{width: "100%", height: "100%", padding: (isMobile) ? "0" : "2em"}}>
        {completed[activeStep] && (
            <>
                <Grid sx={{ textAlign: "center" }} item xs={12}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Box sx={{ textAlign: "start" }}>
                            {columns?.filter((column:any) => column?.type !== 'actions')?.map((column:any) => (
                            <Typography variant="body1" key={column?.field}>
                                {column?.headerName}: {selectedItem?.[column?.field] || 'N/A'}
                            </Typography>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "end", alignItems: "center", height: "100px"}}>
                    <Button variant="contained" onClick={() => handleClose((close:boolean) => !close)}>Close</Button>
                </Grid>
            </>
        )}
        {!completed[activeStep] && (
        <Grid container sx={{justifyContent: "center", width: "100%"}} gap={1}>
            {(columns && columns.length > 0) && 
            columns.filter((column:any) => column?.type !== 'actions' && 
            !excludedFields.includes(column?.field)).map((column:any) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={column?.field}>
                {column?.type === 'date' ? (

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ mt: 1.5, mb: 2, width: "auto", minWidth: 180 }}
                            formatDensity="dense"
                            label={column?.headerName}
                            defaultValue={dayjs()}  // Use dayjs() to initialize with the current date
                            value={selectedDate}
                            onChange={(newValue) => handleDateChange(column?.field, newValue)}
                        />
                    </LocalizationProvider>
                    
                ) : autocompleteOptions?.[getKey(column?.field)] ? (  
                        <Autocomplete
                            freeSolo
                            multiple={column?.type === 'array'}  // Enable multiple if the field is an array
                            fullWidth
                            options={autocompleteOptions?.[getKey(column?.field)]?.options || autocompleteOptions?.[`${getKey(column?.field)}s`]?.options || []}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={column?.headerName}
                                    variant="standard"
                                    error={formErrors && !!formErrors?.[column?.field]}
                                    helperText={formErrors && formErrors[column?.field] ? 'required' : ''}
                                />
                            )}
                            value={formData[column?.field] || (column.type === "array" ? [] : "")}  // Set initial value based on whether it's an array
                            onChange={(_, newValue:string) => {
                                
                                if ((column.field === 'job_type' || column.field === 'job_type_id') && onJobTypeChange) {
                                    console.log(newValue)
                                    onJobTypeChange(newValue);
                                }
                                if (column.field === 'company_id' && onCompanyChange) {
                                    onCompanyChange(newValue);
                                }
                                setFormData((prevState: any) => ({
                                    ...prevState,
                                    [column?.field]: column.type === 'array'
                                        ? newValue  // Assign newValue directly if it's an array
                                        : newValue || "",  // Handle single value
                                }));
                            }}
                        />

                    ) : (
                        <TextField
                            label={column?.headerName}
                            fullWidth
                            type={column?.type === 'number' ? 'number' : 'string'}
                            name={column?.field}
                            value={formData[column?.field] || ''}
                            onChange={handleChange}
                            variant="standard"
                            error={formErrors && !!formErrors?.[column?.field]}
                            helperText={formErrors && formErrors?.[column?.field] ? 'required' : ''}
                        />
                    )}
            </Grid>
            ))}
        </Grid>
        )}

        {!completed[activeStep] && 
        <Grid item xs sx={{display: "flex", justifyContent: "end", padding: (isMobile) ? "0" : "1em", marginTop: "1em"}}>
            <Button variant="contained" onClick={handleSave}>Save</Button>
        </Grid>
        }
    </Grid>
    );
}

export default FormComponent;