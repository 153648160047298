import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, useTheme } from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import { logout, reset } from '../auth/authSlice';
import CustomButton from '../components/button/CustomButton';
import { useNavigate } from 'react-router-dom';

interface InactiveSession {
    open: boolean;
    onClose: () => void;
}

const InactiveSessionModal: React.FC<InactiveSession> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [timeLeft, setTimeLeft] = useState<number>(30); // 30 seconds to continue the session

    const handleLogout = async () => {
        await dispatch(logout());
        dispatch(reset());
        navigate('/login');
    };

    useEffect(() => {
        let timerId: any;

        if (open) {
            timerId = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timerId);
                        handleLogout(); //  logout if timer reaches 0
                        return 0;
                    }
                    return prevTime - 1; // Decrement time left
                });
            }, 1000);
        }

        return () => {
            clearInterval(timerId); // Cleanup timer on close or unmount
        };
    }, [open, timeLeft, handleLogout]);

    

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="session-expired-dialog-title">
            <DialogTitle id="session-expired-dialog-title" sx={{textAlign: "center"}}>Inactivity Warning</DialogTitle>
            <DialogContent>
            <DialogContentText>
                You have been inactive for 5 minutes, your session will be terminated in{' '}
                <Typography component="span" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                    {timeLeft}
                </Typography>{' '}
                seconds. Do you wish to stay logged in?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" onClick={handleLogout} color="primary" size="small">  
                    No
                </CustomButton>
                <CustomButton variant="contained" onClick={onClose} color="primary" size="small">
                    Yes
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default InactiveSessionModal;
