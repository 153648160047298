import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';

const DeliveryMethodEditComponent = (props:any) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event:any) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const deliveryOptions = ['PICKUP', 'DELIVERY'];

  return (
    <Select
      value={value}
      onChange={handleChange}
      fullWidth
    >
      {deliveryOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DeliveryMethodEditComponent;
