import compnayColumns from "./columns/companyColumns";
import customerColumns from "./columns/customerColumns";
import jobTypeColumns from "./columns/jobTypeColumns";
import employeeColumns from "./columns/employeeColumns";
import CustomGridToolbar from "../components/data-grid/toolbars/CustomGridToolbar";
import steps from "./steps/steps";
import { apiRouteMap } from "../routes/urls";
import useDivisionColumns from "./columns/divisionColumns";
import { useGetCompanyNumbersQuery } from "../dropdowns/dropdownApi";
import { useAppSelector } from "../../app/hooks";
 
export const useOrgUnitConfig = () => {
    const {company, division} = useAppSelector((state:any) => state.auth);
    const {data: companies} = useGetCompanyNumbersQuery();
    const divColumns = useDivisionColumns(companies);

    return {
        tabs: [  
            { 
                route: "/organizational-units/company",
                index: 0,
                label: 'Company',
                expandable: false,
                deletable: true,
                steps: steps?.createCompany,
                header: false,
                columns: compnayColumns,
                panelColumns: [],
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get("/organizational-units/company").url(),
                tabWithFS: false, 
            },
            { 
                route: "/organizational-units/divisions",
                index: 1,
                label: 'Division',
                expandable: false,
                deletable: true,
                steps: steps?.createDivision,
                header: false,
                columns: divColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/divisions').url(),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/job-types",
                index: 2,
                label: 'Job Types',
                expandable: false,
                deletable: true,
                steps: steps?.createJobType,
                header: false,
                columns: jobTypeColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/job-types').url({division_id: division?._id}),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/customers",
                index: 3,
                label: 'Customers',
                expandable: false,
                deletable: true,
                steps: steps?.createCustomer,
                header: false,
                columns: customerColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/customers').url({company_id: company?._id}),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/supervisors",
                index: 4,
                label: 'Supervisors',
                expandable: false,
                deletable: true,
                steps: steps?.createSupervisor,
                header: false,
                columns: employeeColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/supervisors').url({division_id: division?._id}),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/estimators",
                index: 5,
                label: 'Estimators',
                expandable: false,
                deletable: true,
                steps: steps?.createEstimator,
                header: false,
                columns: employeeColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/estimators').url({division_id: division?._id}),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/foremen",
                index: 6,
                label: 'Foremen',
                expandable: false,
                deletable: true,
                steps: steps?.createForeman,
                header: false,
                columns: employeeColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/foremen').url({division_id: division?._id}),
                tabWithFS: false 
            },
            { 
                route: "/organizational-units/jc-accountants",
                index: 7,
                label: 'JC Accountants',
                expandable: false,
                deletable: true,
                steps: steps?.createJCAccountant,
                header: false,
                columns: employeeColumns,
                panelColumns: undefined,
                toolbar: CustomGridToolbar,
                panelToolbar: undefined,
                getUrl: apiRouteMap.get('/organizational-units/jc-accountants').url({division_id: division?._id}),
                tabWithFS: false 
            },
        ],
        
    };
};
