import { GridColDef } from "@mui/x-data-grid-premium";

const CemexOrderColumns: GridColDef[]  = [
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'string',
      width: 160,
    },
    {
      field: 'orderCode',
      headerName: 'Order Code',
      type: 'string',
      width: 160,
    },
    {
      field: 'createdDateTime',
      headerName: 'Created Date Time',
      type: 'string',
      width: 160,
    },
    {
      field: 'programmedDateTime',
      headerName: 'Programmed Date Time',
      type: 'string',
      width: 160,
    },
    {
      field: 'requestedDateTime',
      headerName: 'Requested Date Time',
      type: 'string',
      width: 160,
    },
    {
      field: 'programmedDateTimeUtc',
      headerName: 'Programmed Date Time UTC',
      type: 'string',
      width: 160,
    },
    {
      field: 'requestedDateTimeUtc',
      headerName: 'Requested Date Time UTC',
      type: 'string',
      width: 160,
    },
    {
      field: 'purchaseOrder',
      headerName: 'Purchase Order',
      type: 'string',
      width: 160,
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      type: 'string',
      width: 160,
    },
    {
        field: 'totalQuantity',
        headerName: 'Total Quantity',
        type: 'string',
        width: 160,
      },
      {
        field: 'totalProducts',
        headerName: 'Total Products',
        type: 'string',
        width: 160,
      },
      {
        field: 'isMultipleOrder',
        headerName: 'Is Multiple Order',
        type: 'string',
        width: 160,
      },
      {
        field: 'orderType_orderTypeId',
        headerName: 'Order Type - Order Type Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'orderType_orderTypeCode',
        headerName: 'Order Type - Order Type Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'orderType_orderTypeDesc',
        headerName: 'Order Type - Order Type Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'status_statusId',
        headerName: 'Status - Status Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'status_statusCode',
        headerName: 'Status - Status Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'status_statusDesc',
        headerName: 'Status - Status Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'status_dispatch',
        headerName: 'Status Dispatch',
        type: 'string',
        width: 160,
      },
      {
        field: 'customer_customerId',
        headerName: 'Customer - Customer Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'customer_customerCode',
        headerName: 'Customer - Customer Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'customer_customerDesc',
        headerName: 'Customer - Customer Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'shippingCondition_shippingConditionId',
        headerName: 'Shipping Condition - Shipping Condition Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'shippingCondition_shippingConditionCode',
        headerName: 'Shipping Condition - Shipping Condition Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'shippingCondition_shippingConditionDesc',
        headerName: 'Shipping Condition - Shipping Condition Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'jobsite_jobsiteId',
        headerName: 'Jobsite - Jobsite Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'jobsite_jobsiteCode',
        headerName: 'Jobsite - Jobsite Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'jobsite_jobsiteDesc',
        headerName: 'Jobsite - Jobsite Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_contactId',
        headerName: 'Contact - Contact Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_name',
        headerName: 'Contact Name',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_phone',
        headerName: 'Contact Phone',
        type: 'string',
        width: 160,
      },
      {
        field: 'contract_contractId',
        headerName: 'Contract - Contract Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'contract_contractCode',
        headerName: 'Contract - Contract Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'contract_grossPrice',
        headerName: 'Contract - Gross Price',
        type: 'string',
        width: 160,
      },
      {
        field: 'contract_taxAmount',
        headerName: 'Contract - Tax Amount',
        type: 'string',
        width: 160,
      },
      {
        field: 'contract_totalPrice',
        headerName: 'Contract - Total Price',
        type: 'string',
        width: 160,
      },
      {
        field: 'commercialChannel_commercialChannelId',
        headerName: 'Commercial Channel - Commercial Channel Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'commercialChannel_commercialChannelCode',
        headerName: 'Commercial Channel - Commercial Channel Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'productType_productTypeId',
        headerName: 'Product Type - Product Type Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'productType_productTypeCode',
        headerName: 'Product Type - Product Type Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'productType_productTypeDesc',
        headerName: 'Product Type - Product Type Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'unit_unitId',
        headerName: 'Unit - Unit Id',
        type: 'string',
        width: 160,
      },
      {
        field: 'unit_unitDesc',
        headerName: 'Unit - Unit Description',
        type: 'string',
        width: 160,
      },
      {
        field: 'unit_unitCode',
        headerName: 'Unit - Unit Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_countryAreaCode',
        headerName: 'Contact - Country Area Code',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_extension',
        headerName: 'Contact - Extension',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_email',
        headerName: 'Contact - Email',
        type: 'string',
        width: 160,
      },
      {
        field: 'contact_contactCode',
        headerName: 'Contact - Contact Code',
        type: 'string',
        width: 160,
      }
  ];
  
  export default CemexOrderColumns;