import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DataGridPremium, GridEventListener, GridRowEditStopReasons, GridRowModesModel } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import LotDetailItems from './LotDetailItems';
import { DataGridProProps, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import CustomButton from '../../components/button/CustomButton';
import styles from './detail.module.css';
import { useCreateDataMutation, useGetDataQuery, useUpdateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../../components/snackbar/snackbarSlice';
import { apiRouteMap } from '../../routes/urls';

const CreateOrAddLots = ({ step, activeStep, completed, setCompleted, handleClose, handleRefresh }:any) => {
  const {selectedItem} = useAppSelector((state:any) => state.api);
  const {project_id, start_id} = useParams();
  const context = step?.route?.split("/")[1];
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [excludedLots, setExcludedLots] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [lotNumber, setLotNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [orientation, setOrientation] = useState<string>("");
  const lotNumberRef = useRef<any>(null);

  const queryParams = useMemo(() => {
    if (!context) return null;
    return {
      tagName: "/lots",
      url: 'lots',
      params: { 
        project_id
      }
    };
  }, [project_id, context]);
  
  const {data: response } = useGetDataQuery(queryParams);
  const [createData, {isLoading}] = useCreateDataMutation();
  const [updateData] = useUpdateDataMutation();


  useEffect(() => {
    if(response?.data){
      const filter = Array.isArray(response?.data) && response?.data?.filter((item:any) => {
        return item?.starts?.some((startItem:any) => startItem.start_id === start_id || startItem.start_id === selectedItem?._id);
      });
      setExcludedLots(filter);
    }
  }, [response?.data]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 
  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
  }

  const handleCreateLot = async () => {
    //setIsSaving(true);
    try {
      if(project_id || selectedItem){
        const id = project_id  || selectedItem?.project_id;
        const data = {
          lot_number: lotNumber,
          address,
          garage_orientation: orientation
        }
 
        const payload = await createData({
          tagName: "/lots",
          url: apiRouteMap.get("/lots").createItem(id,selectedItem?._id || start_id),
          body: data, 
        }).unwrap();
        dispatch(showSnackbar({ message: payload?.message || 'Operation successful', type: 'success' }));       
      }
    } catch (error:any) {
      const errorMessage = error?.data?.message || 'Failed to create data';
      dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
    } 
};

  const handleSaveSelection = async () => {
    try {
      if(rowSelectionModel.length > 0){
        for(const entry of rowSelectionModel){
          await updateData({
            tagName: `/starts/${selectedItem?._id}`,
            url: `/lots?id=${entry}&start_id=${selectedItem?._id}`,
          }).unwrap();

        }
        dispatch(showSnackbar({ message: 'Operation successful', type: 'success' }));
        setCompleted({[0]: true});
      }
      
    } catch (error:any) {
      const errorMessage = error?.data?.message || 'Failed to create data';
      dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
    }
  }

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const handleRowSelection = (params:GridRowParams) => {
    const found = excludedLots?.find((lot:any) => lot?._id === params.id);
    if(found) return false;
    return true;
  }

  const getDetailPanelContent = React.useCallback(({ row }:any) => {
    const rowData = response?.data?.find((r:any) => r._id === row._id);
    const panelItems = rowData?.starts;
      return (
        <LotDetailItems
          handleRefresh={handleRefresh} 
          row={row} 
          items={panelItems} 
          context={context}
        />
      );
  }, [response]);
  
  const getDetailPanelHeight = React.useCallback<NonNullable<DataGridProProps['getDetailPanelHeight']>>(() => 'auto' as const, []);

  const handleRowClassName = (params:any) => {
    const found = excludedLots?.find((lot:any) => lot?._id === params.id);
    return found ? `${styles.inactive}` : ''
  }

  return (
    <Grid container spacing={2} justifyContent="center" sx={{width: "100%", height: "100%", overflowX: "scroll"}}>
      <Grid container justifyContent="flex-end" sx={{padding: 1}} alignItems="flex-end" spacing={2}>
        <Grid item md={3} xs={12}>
          <TextField
            size="small"
              variant="filled"
              autoFocus
              margin="dense"
              label="Lot #"
              type="string"
              fullWidth
              name="lot_number"
              value={lotNumber || ""}
              onChange={e => setLotNumber(e.target.value)}
              inputRef={lotNumberRef}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            size="small"
            variant="filled"
            margin="dense"
            label="Address"
            type="string"
            fullWidth
            name="address"
            value={address || ""}
            onChange={e => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            size="small"                     
            options={["R", "L"]}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                
                {...params} 
                variant="filled" 
                label="Orientation" 
                margin="dense" 
              />
            )} 
            value={orientation}
            onChange={(event, newValue) => setOrientation(newValue || "")}               
          />
        </Grid>
        <Grid item md={3} xs={12} sx={{p:0.25, display: "flex", justifyContent: "flex-start"}}>
          <CustomButton color="primary" size="small" variant="outlined" onClick={handleCreateLot}>Create Lot</CustomButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{height: "400px", maxHeight: "800px"}}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(255,255,255,0.5)"
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                document: false,
                created_at: false,
                created_by: false,
                updated_at: false,
                updated_by: false
              },
            },
          }} 
          checkboxSelection
          editMode="row"
          getRowId={row => row?._id}
          isRowSelectable={(params:any) => handleRowSelection(params)}
          density="compact"
          rows={response?.data || []}
          columns={step?.column || []}
          loading={isLoading}
          rowHeight={60}
          rowBuffer={10}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={handleSelectionModelChange}
          onRowEditStop={handleRowEditStop}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          slots={{ 
            detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
            detailPanelCollapseIcon: KeyboardArrowUpSharpIcon,
          }}
          getRowClassName={(params:any) => handleRowClassName(params)}

        />
      </Grid>

      <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
        {!completed[activeStep] &&
        <CustomButton color="primary" size="small" sx={{marginLeft: 1}} variant="contained" onClick={handleSaveSelection}  component="label">
            Add Lots to Start
        </CustomButton>}
        {completed[activeStep] &&
        <CustomButton color="primary" size="small" sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
            Close
        </CustomButton>} 
      </Grid>
    </Grid>
  );
};

export default CreateOrAddLots;
