import { GridColDef } from "@mui/x-data-grid-premium";

export const whMaterialPricingColumns: GridColDef[] = [
    {
      field: 'part_number',
      headerName: 'Part Number',
      sortable: true,
      type: 'string',
      width: 120,
      editable: true,
    },
    {
      field: 'part_description',
      headerName: 'Description',
      width: 260,
      type: 'string',
      sortable: true,
      editable: true
    },
    {
      field: 'cost_code',
      headerName: 'Cost Codes',
      type: 'string',
      sortable: true,
      width: 160,
      editable: true,
    },
    {
      field: 'cost_type',
      headerName: 'Cost Type',
      type: 'string',
      sortable: true,
      width: 160,
      editable: true,
    },
    {
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
      type: 'string',
      width: 80,
      editable: true,
    }
];