
const stepOne = [
  { 
    field: 'job_type', 
    headerName: 'Job Type',
    width: 160, 
    type: 'string' ,
    autocompletion: true, 
    editable: true,
    filterable: true,
  }
];

const stepTwo = [
  // { 
  //   field: 'job_type', 
  //   headerName: 'Job Type',
  //   width: 160, 
  //   type: 'string' ,
  //   autocompletion: true, 
  //   editable: true,
  //   filterable: true,
  // },
  { 
    field: 'grouping_type', 
    headerName: 'Grouping Type',
    width: 160, 
    type: 'string' ,
    autocompletion: false, 
    editable: true,
    filterable: true,
  },
  { field: 'specification', 
    headerName: 'Specification', 
    width: 160, 
    type: 'string', 
    editable: true,
    filterable: true 
  },
  { field: 'specification_value', 
    headerName: 'Specification Value', 
    width: 160, 
    type: 'string', 
    editable: true,
    filterable: true 
  },
  { field: 'identifying_notes', 
    headerName: 'Notes', 
    width: 220, 
    type: 'string', 
    editable: true,
    filterable: true 
  },

];

export default {stepOne, stepTwo};