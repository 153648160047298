import { GridColDef } from "@mui/x-data-grid-premium";

export const SubjobColumns: GridColDef[] = [
    {
        field: 'job_number',
        headerName: 'Job #',
        width: 80,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin"
    },
    {
        field: 'subjob_number',
        headerName: 'Subjob #',
        width: 80,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin"
    },
    {
        field: 'job_type',
        headerName: 'Job Type',
        width: 160,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin"
    },
    {
        field: 'supervisor',
        headerName: 'Supervisor',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: true,
        valueGetter: (params:any) => {
            return params?.supervisor?.employee_name;
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: true,
        renderCell: (params:any) => (
            <span style={{ color: String(params?.value).toUpperCase() === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'red' : '#0074D9' }}>
                {`${params.value}`.toUpperCase()}
            </span>
        ),
    },
    {
        field: 'start_date',
        headerName: 'Start Date',
        width: 120,
        type: 'date',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin",
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: false,
        editable: true
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin",
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 220,
        type: 'date',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin",
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
    },  
    {
        field: 'updated_by',
        headerName: 'Updated By',
        width: 220,
        type: 'string',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin",
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 220,
        type: 'date',
        headerAlign: 'left',
        sortable: true,
        editable: false,
        // access: "admin",
        valueGetter: (params:any) => params.value ? new Date(params.value) : null,
        valueFormatter: (params: any) => {
            if (!params.value) {
              return '';
            }
            const date = new Date(params.value);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
    },
];
