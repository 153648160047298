import { Grid } from "@mui/material";
import DetailHeader from "../components/header/DetailHeader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useState, useEffect, useMemo } from "react";
import { AutocompleteOptions } from "../../utils/interfaces";
import { useGetSupervisorsQuery, useGetAllJobTypesQuery, useGetCustomersQuery } from "../dropdowns/dropdownApi";
import { apiRouteMap } from "../routes/urls";
import { showSnackbar } from "../components/snackbar/snackbarSlice";
import { useUpdateDataMutation } from "../api/apiSliceV2";
import { useLocation } from "react-router-dom";
import { setSelectedItem } from "../components/stepper/stepperSlice";

export const translationRequired = [
    "customer_id",
    "job_types",
    "job_type",
    "supervisor",
    "job_type_id"
];

const StartHeader = (props:any) => {
    const {isHeaderVisible} = props;
    const { company, division } = useAppSelector((state: any) => state.auth);
    const { statuses } = useAppSelector((state: any) => state.dropdown);
    const dispatch = useAppDispatch();
    const params = useMemo(() => ({ company_id: company?._id }), [company]);
    const { data: customers } = useGetCustomersQuery(params);
    const { selectedItem} = useAppSelector((state: any) => state.api);
    const location = useLocation();
    const jobTypeParams = useMemo(() => ({ division_id: division?._id }), [division]);
    const { data: all_job_types } = useGetAllJobTypesQuery(jobTypeParams);
    const {data: supervisors} = useGetSupervisorsQuery(jobTypeParams);
    const path = (location.pathname.split("/").length > 3) ?
    `/${location.pathname.split("/")[3]}`
    :
    `/${location.pathname.split("/")[1]}`;
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});
    const [updateData] = useUpdateDataMutation();

    const translateIds = (jsonData:any) => {
        try {
            for (const [key, val] of Object.entries(jsonData) as [string, any]) {
                if (translationRequired.includes(key)) {
                    switch (key) {
                        case "customer_id":
                            const customer = autocompleteOptions?.[key]?.data?.find((c:any) => c?.customer_description === val?.customer_description);
                            jsonData[key] = customer?._id || val; 
                            break;
                        case "supervisor":
                            const supervisor = autocompleteOptions?.[key]?.data?.find((s:any) => {
                                return s?.employee_name === val;
                            });
                            jsonData[key] = supervisor?._id || ""; 
                            break;
                        case "job_type_id":
                            const translated = autocompleteOptions?.[key]?.data?.find((j: any) => j?.job_type_description === jsonData[key]);
                            console.log(key, translated)
                            jsonData[key] = translated?._id;  
                            break;
                         default:
                            break;
                    }
                }
            }
            return jsonData;
        } catch (error) {
            console.log(error);
        }
        return jsonData;
    };

    const handleSave = async (formData:any) => {
        try {

            const body = translateIds(formData);
            const response = await updateData({
                tagName: `/starts/${selectedItem?._id}`,
                url: apiRouteMap.get(path).update(selectedItem?._id),
                body,
            }).unwrap();
            dispatch(setSelectedItem(response?.data));
            dispatch(showSnackbar({ message: response?.message || 'Start update is successful', type: 'success' }));
        } catch (error: any) {
            const errorMessage = error?.data?.message || 'Start update failed, please try again';
            dispatch(showSnackbar({ message: `Error: ${errorMessage}`, type: 'error' }));
        }
    };

    useEffect(() => {
        setAutocompleteOptions({
            customer_id: {
                data: customers?.data || [],
                options: customers?.data?.map((customer: any) => customer.customer_description) || [],
            },
            job_type_id: {
                data: all_job_types?.data || [],
                options: all_job_types?.data?.map((job_type: any) => job_type.job_type_description) || [],
            },
            supervisor: {
                data: supervisors?.data || [],
                options: supervisors?.data?.map((supervisor: any) => supervisor.employee_name) || [],
            },
            status: {
                data: statuses || [],
                options: statuses || [],
            },
        });
    }, [customers, all_job_types, statuses, supervisors]);

    return(
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <DetailHeader
            isHeaderVisible={isHeaderVisible}
            autocompleteOptions={autocompleteOptions}
            editable={true}
            handleSave={handleSave}
            path={path}
          />
        </Grid>
    );
}

export default StartHeader;