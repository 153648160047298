import { Box, Button, Grid } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useState } from 'react';
import ConfirmRemoveAll from './ConfirmRemoveAll';
import DropdownMenuButton from '../../button/DropdownButton';
import DynamicStepper from '../../stepper/Stepper';

const CustomGridToolbar = (props:any) => {
    const { context, exportItems, handleDeleteAll} = props;
    const [open, setOpen] = useState<boolean>(false);

    return(
        <Box>
            <GridToolbarContainer sx={{fontWeight: 600}}>
                {context?.steps?.length > 0 && 
                    <DynamicStepper
                        context={context}
                        {...props}
                    />
                }
                <GridToolbarFilterButton sx={{fontWeight: 600}} />
                {/* <GridToolbarColumnsButton sx={{fontWeight: 600}} /> */}
                {/* <GridToolbarExport sx={{fontWeight: 600}} /> */}

                {exportItems && 
                <Grid item xs={12}>
                    <DropdownMenuButton {...props}/>
                </Grid>}

            {handleDeleteAll &&
                <Button  sx={{ marginLeft: 0.5 }} color="error" variant="text" onClick={() => setOpen((open:boolean) => !open)}>
                    Delete All
                </Button>
            }
            </GridToolbarContainer>
            <ConfirmRemoveAll
                open={open}
                setOpen={setOpen}
                handleDelete={handleDeleteAll}
            />
        </Box> 
    )
}

export default CustomGridToolbar;
